@use 'src/assets/styles/base/mixins' as *;

$minWidth: (80, 90, 100, 120, 130, 140, 150, 180, 200, 220, 225, 250, 270, 300, 350, 400, 450, 500);

@each $value in $minWidth {
  .min-w-#{$value} {
    min-width: $value + px !important;
  }
}

/* output classes - 
.min-w-80
.min-w-90
and so on...
*/

$Width: (55, 60, 70, 80, 90);

@each $w in $Width {
  .w-#{$w} {
    width: $w + px;
  }
}

/* output classes - 
.w-55
.w-60
and so on...
*/

.width-fit-content {
  width: fit-content !important;
}

.w-85 {
  width: 85%;
}
