@use 'src/assets/styles/base/mixins' as *;

i-feather.feather-24 {
  @include size(24px);
}

i-feather.feather-20 {
  @include size(20px);
}

i-feather.feather-16 {
  @include size(16px);
}

/* Base url path of icon folder */

$common-path: '/assets/images/icons/';

/* Icon list */
$icons: (
  'feather-database': 'feather-database',
  'feather-shield': 'feather-shield',
  'hospitalization': 'hospitalization',
  'allergies': 'allergies',
  'medications': 'Medications',
  'user-engagement': 'user-engagement',
  'patient-status': 'patient-status',
  'identified-patient-list': 'identified-patient-list',
  'injection': 'injection',
  'alert-most-reported ': 'alert-most-reported ',
  'alerts-resolved': 'alerts-resolved',
  'contact-info': 'contact-info',
  'programs': 'programs',
  'screened-pass': 'screened-pass',
  'stop-watch': 'stop-watch',
  'language': 'language',
  'pdf': 'pdf',
  'reach-trial': 'reach-trial',
  'reach-trial-blue': 'reach-trial-blue',
  'system-check-2': 'system-check-2',
  'resend': 'resend',
  'drag-handle': 'drag-handle',
  'drag-handle-white': 'drag-handle-white',
  'esignature': 'esignature-normal',
  'transition': 'transition',
  'vitals-signs': 'vitals-signs',
  'column-1': 'column-1',
  'column-2': 'column-2',
  'row-1': 'row-1',
  'no-preview': 'no-preview',
  'health-tracker': 'health-tracker',
  'mail-invite': 'mail-invite',
  'mail-invite-white': 'mail-invite-white',
  'audit-trail': 'audit-trail',
  'affiliation-code': 'affiliation-code',
  'labs': 'labs',
  'user-identifier-card': 'user-identifier-card',
  'oxygen': 'oxygen',
  'cm': 'cm',
  'movement': 'movement',
  'bloodpressure': 'bloodpressure',
  'weight': 'weight',
  'translate-disabled': 'translate-disabled',
  'time-reset': 'time-reset',
  'user-setting': 'user-setting',
  'sleep': 'sleep',
  'sleeprem': 'sleeprem',
  'sleeptimeswoken': 'sleeptimeswoken',
  'blood-sugar': 'blood-sugar',
  'heart-rate': 'heart-rate',
);

@each $icon-name, $icon-url in $icons {

  i-feather[name='#{$icon-name}'],
  i-feather.#{$icon-name} {
    background: url('#{$common-path}#{$icon-url}.svg') no-repeat center 100%/100%;
  }
}

.client-active .feather-database,
.expend-active .feather-database {
  background-image: url('#{$common-path}db-active.svg');
}

i-feather[name='cm'],
.cm {
  background-position: center;
  background-size: auto;
}

i-feather[name='movement'],
i-feather.movement {
  background-size: auto;
}