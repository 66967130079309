@use 'src/assets/styles/base/mixins' as *;

carousel {
  &.carousel {
    width: calc(100% - 476px);
    margin-right: 25px;
    z-index: 1 !important;
    height: 160px !important;

    @include media('down', xl) {
      width: calc(100% - 350px);
      height: 170px !important;
    }

    @include media('down', lg) {
      width: 100%;
    }
  }

  .carousel-container {
    padding-left: 10px;
  }

  .carousel-arrow {
    box-shadow: none !important;
    background-color: transparent !important;
    background-size: 24px !important;
  }

  .carousel-arrow-disabled {
    display: none;
  }

  .carousel-arrow.carousel-arrow-prev {
    left: -12px !important;
    background-image: url('../../images/client-menu/chevron-left.svg') !important;
  }

  .carousel-arrow.carousel-arrow-next {
    right: -12px !important;
    background-image: url('../../images/client-menu/chevron-right.svg') !important;
  }
}
