@use 'src/assets/styles/base/mixins' as *;

.form-control {
  max-width: 206px;
  background-color: var(--txtbox-enabled);
  color: var(--primaryTextColor);
  height: auto;
  font-size: var(--txtbox-font-size);
  line-height: var(--txtbox-line-height);
  font-family: var(--txtbox-font-family);
  border-color: transparent;
  min-height: 32px;
  padding: 6px 8px;
  box-shadow:
    0 -1px 1px var(--txtbox-border) inset,
    0 1px 0 #fff;
  transition: 0.3s color;

  &:hover {
    background-color: var(--txtbox-enabled-hover);
  }

  &:focus {
    background-color: var(--txtbox-focus-background);
    border-color: var(--txtbox-focus-border);
    box-shadow: none;
    outline: 4px solid var(--txtbox-focus-outline);
  }

  &.path {
    max-width: 428px;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    background-color: var(--txtbox-disabled);
    color: var(--disabledTextColor);
  }

  &.is-invalid {
    border-color: var(--negativeColor);

    &:focus {
      outline-color: var(--txtbox-error-focus-outline);
    }
  }
}

.form-control.lg {
  max-width: 428px;
}

textarea {
  resize: none;

  &.form-control {
    max-width: 428px;
    height: 128px;
  }
}

/* remove default arrows from number form field */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* --- input with icon / input group --- */

.input-with-icon {
  display: flex;
  width: auto;
  background-color: var(--txtbox-enabled);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow:
    0 -1px 0.5px rgba(0, 0, 0, 0.1) inset,
    0 1px 0 #fff;

  &:hover {
    background-color: var(--txtbox-enabled-hover);
  }

  .form-control {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    max-width: 100%;

    &:focus {
      background-color: var(--fgColor);
      border-color: rgba(81, 102, 177, 0.8);
      box-shadow: 0 0 0 0.2rem rgba(81, 102, 177, 0.25);
    }
  }

  .input-group-icon {
    @include flex-center();

    padding-inline: 8px;
    border-radius: 8px;
  }

  &> :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &> :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/* For input-icon-inside */

.input-icon-inside {
  position: relative;

  &>label {
    min-height: 32px;
    margin-bottom: 0;
  }

  i-feather {
    position: absolute;
    right: 10px;
    bottom: 6px;
  }
}

/* For date with icon */

.input-icon {
  position: relative;
  width: fit-content;

  .icon {
    position: absolute;
    right: 20px;
    top: 9px;
  }
}

/*  #######   time-picker   #######  */

.time-picker-container {
  .form-control {
    margin-right: 0;
  }
}

/* #######   File Input   ####### */

.form-control.file {
  max-width: 428px;
  position: relative;
  height: auto;
  padding: 0;

  .file-text {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 40px;

    span {
      margin-top: 5px;
    }
  }

  .form-control-file {
    min-height: 116px;
    opacity: 0;
  }
}

.selected-file {
  height: 64px;
  width: 64px;
  background-color: var(--fgColor);
  border-radius: 10px;
  padding: 4px;
  margin: 20px 0 10px 0;
  position: relative;

  i {
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 1.25rem;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
    overflow: clip;
    border: 1px solid var(--separatorColor);
  }
}

/*  #######   Search   #######  */

.input-search {
  @extend .form-control;

  padding: 0;

  .input-group-text {
    border: none;
    padding: 2px 8px;
    background-color: transparent;
  }

  i-feather[name='search'] {
    width: 20px;
    height: 20px;
    color: var(--disabledTextColor);
  }
}

.input-group.input-search {
  background-color: var(--txtbox-enabled);
  border-radius: 8px;

  .form-control {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-top: -1px;

    &:focus {
      background-color: var(--fgColor);
      border-color: rgba(81, 102, 177, 0.8);
    }
  }
}

.search-input {
  position: relative;

  input.form-control {
    padding-right: 36px;
  }

  i-feather[name='search'],
  i-feather[name='x'] {
    position: absolute;
    left: auto;
    right: 8px;
    bottom: 5px;
  }

  &.search-100 {
    .form-control {
      max-width: none;
      width: 100%;
    }

    i-feather[name='search'],
    i-feather[name='x'] {
      right: 12px;
      left: initial;
    }
  }
}