@use 'src/assets/styles/base/mixins' as *;

/* new json viewer */

.nxt-json-view {
  .json-view {
    padding: 3px 8px;
    margin: 1px 1px 1px 8px;
    border-left: 1px dashed var(--separatorColor);

    .toggler {
      height: 16px;
      width: 16px;
      background-image: url('../../../assets/images/icons/chevron-down.svg');
      background-size: 100% 100%;
      background-color: var(--fgColor);
      transform: rotate(0deg) !important;
      transition: transform 0.3s ease-in-out;
      margin-top: 1px !important;
      margin-left: -17px !important;

      &::before,
      &::after {
        content: '' !important;
      }

      &.expand {
        transform: rotate(-90deg) !important;
      }

      & + .value {
        font-family: var(--bold);
        color: var(--primaryTextColor) !important;
      }
    }
  }

  .children {
    margin-left: 0;

    .json-view {
      .toggler[style='visibility: hidden;'] {
        & + .key {
          font-family: var(--bold);
          color: var(--secondaryTextColor);

          & + .value * {
            color: var(--secondaryTextColor) !important;
          }
        }
      }

      .toggler[style='visibility: visible;'] {
        & + .key {
          font-family: var(--bold);
          color: var(--primaryTextColor);

          & + .value {
            font-family: var(--bold);
            color: var(--primaryTextColor);
          }
        }
      }
    }
  }
}

/* old json viewer */

.ngx-json-viewer .segment {
  padding: 3px 8px !important;
  margin-left: 8px !important;
  border-left: 1px dashed var(--separatorColor);

  .segment-main {
    .segment-key {
      font-family: var(--bold);
    }

    .toggler {
      height: 16px;
      width: 16px;
      background-image: url('../../../assets/images/icons/chevron-down.svg');
      background-size: 100% 100%;
      background-color: var(--fgColor);
      transform: rotate(-90deg) !important;
      transition: transform 0.3s ease-in-out;
      margin-top: 1px !important;
      margin-left: -17px !important;

      &::after {
        content: '' !important;
      }

      & + .segment-key {
        color: var(--primaryTextColor) !important;
      }
    }

    &.expanded .toggler {
      transform: rotate(0deg) !important;
    }

    .segment-value {
      white-space: normal;
    }
  }
}
