@use 'src/assets/styles/base/mixins' as *;
@use './input' as *;
@use './mat-select' as *;
@use './button' as *;
@use './switch' as *;
@use './checkbox' as *;

/* {##################   Label   ###################} */

label {
  color: var(--secondaryTextColor);
}

/* {##################   Input   ###################} */

.form-check {
  --radioCheckSize: 20px;

  @include flex(null, null, center);

  padding-left: 0;
  margin-bottom: 0;

  input[type='checkbox'] {
    @include radioCheck(7px);

    flex: 0 0 var(--radioCheckSize);
    max-width: var(--radioCheckSize);
    cursor: pointer;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: var(--toggle-deactivated-border);

    &:disabled {
      cursor: auto;
    }

    &:checked {
      background:
        url('../../images/checked.svg') no-repeat center,
        var(--toggle-activated-background);
      border-color: var(--toggle-activated-border);

      &:disabled {
        @include checkboxDisabled();
      }
    }

    &.disabled:checked {
      @include checkboxDisabled();
    }
  }

  input[type='radio'] {
    @include radioCheck(100%);

    flex: 0 0 var(--radioCheckSize);
    max-width: var(--radioCheckSize);
    cursor: pointer;
    box-shadow: inset 0 -2px 2px 0 var(--toggle-deactivated-border);

    &:checked {
      background: #fff;
      box-shadow: inset 0 0 0 6px var(--toggle-activated-border);
    }

    &:disabled {
      cursor: auto;
    }
  }

  &:has(input[type='checkbox']),
  &:has(input[type='radio']) {
    label {
      margin: 0 0 0 12px !important;
    }
  }
}

.form-group {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &.w-206 {
    width: 206px;
  }
}

.required-info-disabled {
  margin-bottom: 1rem;
  color: var(--disabledTextColor);
}

.form-check-inner {
  width: 100%;
  display: flex;
  align-items: center;
}

.inline-field-prov {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .form-group {
    margin-bottom: 0;
  }
}

.tag-input {
  max-width: 328px;
}

.tag-list {
  width: 330px;
  color: var(--secondaryTextColor);
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.tag-item {
  background: rgba(60, 102, 255, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  gap: 8px;
}

.tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
}

.remove-logo {
  position: absolute;
  right: -7px;
  top: -7px;
  cursor: pointer;
  stroke: #626262 !important;
  fill: #fff !important;
}

.mat-mdc-input-element {
  padding: 6px 8px !important;
  box-sizing: border-box !important;
}

.date-icon {

  .mat-mdc-icon-button,
  .mat-mdc-button-touch-target {
    padding: 0;
    height: 32px;
  }
}

/*  #######   fieldset   #######  */

.date-form-group {
  display: flex;

  .form-group {
    margin-bottom: 0;
  }

  label {
    font-size: 12px;
  }

  input {
    width: 55px;
    margin-right: 16px;

    &.yy {
      width: 70px;
      margin-right: 0;
    }
  }
}

fieldset {
  padding-block: 24px;
  border-bottom: 1px solid var(--defaultGraySecondary);

  h3 {
    padding-bottom: 0.5rem;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.mat-mdc-checkbox {
  font-family: var(--regular);

  @include align-center(8px);

  .mdc-form-field {
    width: 100%;

    &>label {
      padding-left: 0;
    }
  }

  .mdc-checkbox {
    @include size(21px !important);

    box-sizing: border-box;
  }
}

/*  #######   Required   #######  */

label[required]::after {
  content: '*';
}

/*  #######   Validation   #######  */

.form-control.is-invalid {
  background-image: none;
}

.invalid-feedback {
  font-size: 12px;
  margin-top: 8px;
}

.is-radio-invalid {
  border: 1px solid var(--negativeColor) !important;
}

.input-delete {
  @include flex(row, null, center);

  gap: 8px;

  i-feather {
    color: var(--secondaryTextColor);
  }
}

.time-input {
  position: relative;

  i-feather[name='calendar'] {
    right: 12px;
    left: initial;
    position: absolute;
    bottom: 10px;
  }
}

.select-days {
  display: flex;
  gap: 6px;

  button {
    &.btn.btn-days {
      @include flex(row, center, center);

      padding: 10px;
      width: 40px;
      height: 27px;
      background: var(--dove-gray);
      color: var(--disabledTextColor);
      font-family: var(--bold);

      &.selected {
        background-color: var(--primaryColor);
        color: var(--textColorOnPrimary);
      }
    }
  }
}

.inline-form-fields {
  display: flex;
  gap: 16px;
}

/* */
select {
  padding-right: 26px;
  appearance: none;
  background-image: url('../../images/icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) 7px;
  background-size: 20px;
}

/*  #######   Recurrence/Schedule   #######  */

option {
  // font-size: var(--body-font-size) !important;
  // line-height: var(--body-line-height) !important;
  // font-family: font-regular, sans-serif !important;
  // color: var(--secondaryTextColor) !important;
  // margin: 5px 0;
  // height: auto !important;
  // padding: 8px 16px !important;

  &:has(.mat-mdc-option-search) {
    padding-bottom: 0 !important;
  }
}

.date-block {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  span {
    color: var(--secondaryTextColor);
    font-size: 12px;
  }

  .form-control {
    margin: 0;

    &.num-field {
      width: 40px;
    }

    &.date-place {
      width: 110px;
    }
  }
}

.week-days {
  @include align-center(4px);

  margin-bottom: 0;

  .day {
    @include align-center(10px);

    justify-content: center;
    background: var(--dove-gray);
    color: var(--disabledTextColor);
    width: 40px;
    height: 27px;
    padding: 0 10px;
    border-radius: 8px;
    cursor: pointer;

    &.active {
      background-color: var(--primaryColor);
      color: var(--textColorOnPrimary);
    }
  }
}

textarea.form-control {
  max-width: 100%;
}

.input-icon-inside {
  width: 144px;
}

/*  #######   date-time   #######  */

.date-time-field {
  .form-control {
    height: 32px;
  }

  .date-time-inner {
    position: relative;
    width: 206px;

    .date-time-icon,
    app-timepicker {
      position: absolute;
      right: 10px;
      top: 6px;
      color: var(--secondaryTextColor);
    }
  }
}

/*  #######   custom-day   #######  */

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: var(--mud-gray-2);
}

.custom-day.range,
.custom-day:hover {
  background-color: var(--blue-1);
  color: var(--fgColor);
}

.custom-day.faded {
  background-color: var(--blue-opacity-5);
}

.form-group:not(:has(.mat-mdc-slide-toggle), .form-group.form-check) label {
  margin-bottom: 8px;
}

/*  #######   custom-chips   #######  */

.mat-mdc-chip-list-wrapper {
  input.mat-mdc-chip-input {
    width: 206px;
  }
}

.custom-chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .custom-chips {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--chips-background);
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 2px rgba(0, 0, 0, 0.08);
  }

  .feather-16 {
    color: var(--secondaryTextColor);
    cursor: pointer;
  }
}

mat-tab-group {
  form {
    padding-inline: 3px;
  }
}

/*  #######   Responsive   #######  */

@include media('down', sm) {
  .btn-lg {
    min-width: 0;
    min-height: 0;
  }
}