@use 'src/assets/styles/base/mixins' as *;

:root {
  /* #######   Default Color Variables   ####### */
  --primaryColor: #5166b1;
  --textColorOnPrimary: #fff;
  --positiveColor: #28a745;
  --warningColor: #f3c300;
  --negativeColor: #e71d36;

  /* 
  --secondaryColor: #6c757d;
  --textColorOnSecondary: rgba(0, 0, 0, 0.85);
  --accentColor: #946449;
  --textColorOnAccent: #fff; 
  */

  /* Core Color Variables */
  --bgColor: #f6f7f9;
  --fgColor: #fff;
  --separatorColor: #ddd;
  --coreGrayBackground: #f6f6f6;
  --defaultGraySecondary: #eaeaea;
  --tooltipColor: #2c2c2c;
  --textColorInTooltip: #fff;

  /* #######   Text Color Variables   ####### */
  --primaryTextColor: rgba(0, 0, 0, 0.85);
  --secondaryTextColor: rgba(0, 0, 0, 0.64);
  --disabledTextColor: rgba(0, 0, 0, 0.32);
  --linkTextColor: #226bfc;
  --whiteTextColor: #fff;

  /* bootstrap color code overwritten // we can remove once code migrated into ngv-19 */
  --bs-body-color: rgba(0, 0, 0, 0.85);

  /* Text Size and Line Height Variables */
  --h1-font-size: 24px;
  --h1-line-height: 32px;
  --h2-font-size: 20px;
  --h2-line-height: 23px;
  --h3-font-size: 16px;
  --h3-line-height: 18px;
  --body-font-size: 14px;
  --body-line-height: 16px;
  --small-font-size: 12px;
  --small-line-height: 14px;

  /* Button */
  --btn-primaryColor: var(--primaryColor);
  --btn-primaryTextColor: var(--textColorOnPrimary);
  --btn-primaryHoverColor: color-mix(in lab, var(--primaryColor), #000 20%);
  --btn-primaryHoverTextColor: var(--textColorOnPrimary);
  --btn-primaryBorder: transparent;
  --btn-primaryBoxShadow: color-mix(in lab, var(--primaryColor), #000 16%);
  --btn-secondaryColor: transparent;
  --btn-secondaryTextColor: var(--primaryTextColor);
  --btn-secondaryHoverColor: transparent;
  --btn-secondaryHoverTextColor: var(--primaryTextColor);
  --btn-secondaryBorder: transparent;
  --btn-font-family: font-medium;
  --btn-font-size: 14px;
  --btn-line-height: 16px;
  --buttonDisable: #eaeaea;

  /* Text Box Font Size and Line Height  */
  --txtbox-enabled: #eaeaea;
  --txtbox-disabled: #eaeaea;
  --txtbox-enabled-hover: color-mix(in lab, #eaeaea, #000 4%);
  --txtbox-focus-background: #fff;
  --txtbox-border: rgba(0, 0, 0, 0.08);
  --txtbox-font-family: font-regular;
  --txtbox-font-size: 14px;
  --txtbox-line-height: 16px;
  --txtbox-focus-border: rgba(60, 102, 255, 0.8);
  --txtbox-focus-outline: rgba(60, 102, 255, 0.4);
  --txtbox-error-focus-outline: rgba(231, 29, 54, 0.2);

  /* radio checkbox */
  --toggle-deactivated-background: linear-gradient(190deg, #d3d3d3 0%, #eaeaea 100%);
  --toggle-deactivated-border: #d2d2d2;
  --toggle-activated-background: linear-gradient(190deg, #3155d9 0%, #3c66ff 100%);
  --toggle-activated-border: #2d4fcc;

  /* Switch - Mat Slide Toggle */
  --switch-track-background: linear-gradient(190deg, #f3f3f3 0%, #fff 100%);
  --switch-track-border: #d2d2d2;
  --switch-unselected-background: #fff;
  --switch-unselected-border: #d2d2d2;
  --switch-selected-background: linear-gradient(190deg, #2b995b 0%, #34bb6f 100%);
  --switch-selected-border: #2a9659;

  /* Tabber */
  --tab-label-bg: #d3d8eb;
  --tab-bg: #d4d9ea;
  --active-tab-bg: #ecf0f6;
  --active-tab-border: #e3e3e3;

  /*   Font Family   */
  --regular: font-regular, Arial, sans-serif;
  --medium: font-medium, Arial, sans-serif;
  --bold: font-bold, Arial, sans-serif;

  /* Box Shadow */
  --box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1), 0 11px 15px rgba(0, 0, 0, 0.07), 0 4px 20px rgba(0, 0, 0, 0.06);
  --card-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

  /* Other variables */
  --card-varient-background: rgba(101, 133, 245, 0.05);
  --light-silver: #eee;
  --dove-gray: #ddd;
  --silver-stone: #c3c1c1;
  --smoke-gray: #999;

  /* Table */
  --table-background: transparent;
  --table-textColor: var(--secondaryTextColor);
  --table-font-size: var(--body-font-size);
  --table-line-height: var(--body-line-height);
  --table-font-family: var(--regular);
  --table-row-hover: rgba(60, 102, 255, 0.05);
  --table-header-background: var(--primaryColor);
  --table-header-textColor: var(--textColorOnPrimary);
  --table-header-font-family: var(--medium);
  --table-header-sorted-background: color-mix(in lab, var(--primaryColor), #000 40%);
  --bgHighlight: #f7f9ff;

  /* Display */
  --display-1-font-size: 98px;
  --display-1-line-height: 112px;
  --display-2-font-size: 61px;
  --display-2-line-height: 70px;
  --display-3-font-size: 49px;
  --display-3-line-height: 56px;
  --display-4-font-size: 35px;
  --display-4-line-height: 40px;

  /* -------------------- New Variables for UI Update -------------------- */

  --page-header-background: #eaecf1;
  --padding-inline: 24px;
  --chips-background: #f4f4f4;

  /* Site header */
  --header-background: #4e5056;
  --header-text-color: #fff;
  --menu-trigger-background: #0f0f0f;
  --menu-trigger-color: #fff;

  /* Dashboard */
  --sidebar-widget-background: #e2e8ff;
}