@use 'src/assets/styles/base/mixins' as *;

.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
  justify-content: space-between;
}

.criteria-cell {
  .mat-accordion {
    width: 100% !important;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
    background: transparent !important;
    color: var(--secondaryTextColor);

    .mat-expansion-panel-body {
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      font-family: var(--regular);
      font-size: var(--body-font-size);
      line-height: var(--body-line-height);

      .last-updated {
        opacity: 0;
      }
    }

    &.mat-expanded {
      position: static !important;

      .mat-expansion-panel-body {
        opacity: 1;

        .last-updated {
          opacity: 1;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    padding-inline: 0;
    height: auto;
    font-family: var(--regular);
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);

    &:hover {
      background: transparent !important;
    }

    &.mat-expanded {
      height: auto !important;
    }
  }

  .mat-expansion-panel-content[style*='visibility: hidden'] * {
    height: 0;
  }
}

.mat-accordion.mat-accordion-base {
  .mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none !important;
    color: var(--secondaryTextColor);

    &.mat-expansion-panel-spacing {
      margin-block: 0;
    }

    .mat-expansion-panel-header {
      padding-inline: 0;
      font-size: var(--body-font-size);
      font-family: var(--regular);
      background: transparent;
      height: auto;

      &:hover {
        background: transparent !important;
      }

      &.mat-expanded {
        height: auto;
      }
    }

    .mat-expansion-panel-content {
      font-family: var(--regular);
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-indicator {
      width: 15px;

      &::after {
        padding: 4px;
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        position: relative;
        left: -8px;
      }
    }
  }
}
