@use 'src/assets/styles/base/mixins' as *;
@use 'src/assets/styles/pages/global' as *;

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  $active-tab-height: 58px;

  height: 100%;

  @include scrollbar;

  .mat-mdc-tab-list {
    flex-grow: 0 !important;

    .mat-mdc-tab,
    .mat-mdc-tab-link.mdc-tab {
      height: 30px;
      padding: 0;
      min-width: 30px;
      font-family: font-medium, sans-serif;
      background-color: transparent !important;
      color: var(--secondaryTextColor);

      &:not(:last-child) {
        margin-right: 40px;
      }

      .mdc-tab__content {
        width: auto;
        justify-content: space-between !important;

        .mdc-tab__text-label {
          font-size: 16px;
          color: var(--secondaryTextColor);
          letter-spacing: 0.005em;
        }
      }

      .mdc-tab-indicator__content--underline {
        border-top-width: 3px;
        border-color: var(--primaryColor) !important;
      }

      &:hover .mdc-tab__ripple::before {
        opacity: 0;
      }

      &.mdc-tab--active,
      .mat-mdc-tab-link.mdc-tab--active {
        .mdc-tab__text-label {
          color: inherit !important;
          font-family: font-bold, sans-serif;
        }
      }
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-body-content {
    overflow-x: hidden !important;
  }
}

.language-tabs {
  // .mat-mdc-tab-header {
  //   margin-bottom: 16px;
  // }

  h2 {
    color: var(--primaryTextColor);
  }

  .mat-mdc-tab-labels {
    gap: 40px;
  }

  .mat-mdc-tab-list {
    .mat-mdc-tab {
      padding-inline: 3px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.mat-mdc-tab-label-container,
.mat-horizontal-stepper-header-container {
  @extend .page-header;
}

:is(.card-base, .common-card, .mat-mdc-dialog-content, .page-content-wrapper) .mat-mdc-tab-label-container,
:is(.card-base, .common-card, .mat-mdc-dialog-content, .page-content-wrapper) .mat-horizontal-stepper-header-container {
  background-color: transparent;
  padding: 0 !important;
}

:is(.card-base, .common-card, .mat-mdc-dialog-content, .page-content-wrapper) .mat-mdc-tab-header {
  padding-bottom: 24px;
}
