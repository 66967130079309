@use 'src/assets/styles/base/mixins' as *;

.auth-wrapper {
  width: 100%;
  min-height: calc(100vh - 50px);

  @include flex(column, flex-start, center);

  .auth-container {
    width: 408px;
    max-width: 100%;
    padding: 48px;
    border-radius: 16px;
    background-color: var(--fgColor);
    box-shadow:
      0 4px 20px 0 rgba(0, 0, 0, 0.06),
      0 11px 15px 0 rgba(0, 0, 0, 0.07),
      0 6px 7px 0 rgba(0, 0, 0, 0.1);
  }

  .logo {
    img {
      width: 160px;
      margin-block: 76px 40px;
    }
  }

  .form-control {
    max-width: 100%;
  }

  .btn-primary {
    min-width: 118px;
  }

  h1 {
    font-family: var(--bold);
    margin-bottom: 24px;
  }

  /* #######   Responsive   ####### */

  @include media('down', sm) {
    .auth-container {
      min-height: 0;
      padding: 30px 22px;
    }
  }
}

.error {
  color: var(--negativeColor);
}
