@use './assets/styles/library/material' as *;
@use './assets/styles/index' as *;
@use 'src/assets/styles/base/mixins' as *;

body {
  font-family: var(--regular);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  letter-spacing: 0.0025em;
  background-color: var(--bgColor);
}

.hide-class .ck-sticky-panel {
  display: none !important;
}

.system-tabs {
  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    height: auto !important;
  }

  &.h-auto .mat-mdc-tab-body.mat-mdc-tab-body-active {
    min-height: auto !important;
  }
}

.system-tabs .mat-mdc-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}