@use 'src/assets/styles/base/mixins' as *;

/* mat-checkbox */

.mat-mdc-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  left: 1px;
}

.mat-mdc-checkbox-checked {
  .mat-mdc-checkbox-checkmark {
    opacity: 0 !important;
    background: none !important;
  }
}

.mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background: none !important;
}

.mat-mdc-checkbox-indeterminate {
  .mat-mdc-checkbox-frame {
    border-color: var(--toggle-activated-border);
    border-bottom-color: var(--toggle-activated-border);
    background: var(--toggle-activated-background) !important;
  }
}

.mat-mdc-checkbox-layout {
  margin: 0 1.25px 0 0 !important;
}

.mdc-checkbox__native-control {
  width: 21px !important;
  height: 21px !important;
}

.mat-mdc-checkbox-touch-target {
  top: 10px !important;
  height: 20px !important;
  left: 10px !important;
  width: 20px !important;
}

.mdc-checkbox {
  width: auto !important;
  height: auto !important;

  .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    width: 21px;
    height: 21px;
    border-radius: 7px;
    background: var(--toggle-activated-background) !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: var(--toggle-activated-border) !important;
    border-width: 0 0 2px 0 !important;

    .mdc-checkbox__checkmark {
      padding: 3px 4px;
      color: var(--whiteTextColor) !important;
    }

    .mdc-checkbox__mixedmark {
      margin-inline: 4px;
      border-color: var(--whiteTextColor) !important;
    }
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: var(--disabledTextColor) !important;
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])~.mdc-checkbox__background {
    background: var(--toggle-deactivated-background) !important;
    border: 1px solid var(--toggle-deactivated-border) !important;
    border-bottom-width: 2px !important;
  }

  .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background,
  .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox__native-control[data-indeterminate='true'][disabled]~.mdc-checkbox__background,
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])~.mdc-checkbox__background {
    background: var(--toggle-deactivated-background) !important;
    border-color: var(--toggle-deactivated-border) !important;
  }
}

.mat-mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-checkbox-ripple,
.mdc-checkbox:hover .mdc-checkbox__ripple,
.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  display: none !important;
}

.mat-mdc-checkbox label {
  margin-bottom: 0 !important;
}

/* checkbox-col */

.checkbox-col {
  .form-check {
    min-width: calc(100% - 35px);
  }
}

/* checkbox-2-col */

.checkbox-2-col {

  .form-check,
  .list-inline {
    flex-basis: 50%;
    max-width: 50%;
  }

  .form-group:last-child {
    margin-bottom: 16px;
  }
}