@use 'src/assets/styles/base/mixins' as *;

.btn-flex {
  @include flex(null, null, center);

  padding-block: 6px;
}

.btn {
  height: 34px;
  padding: 6px 12px;
  border-bottom-width: 2px;
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  border-radius: 8px;
}

.btn-primary,
.btn-primary.show {
  background-color: var(--btn-primaryColor);
  color: var(--btn-primaryTextColor);
  box-shadow: 0 2px 0 var(--btn-primaryBoxShadow);
  border-color: var(--btn-primaryBorder);

  &:hover,
  &:focus-visible {
    background-color: var(--btn-primaryHoverColor);
    color: var(--btn-primaryHoverTextColor);
    border-color: var(--btn-primaryBorder);
  }

  &:active {
    background-color: var(--btn-primaryHoverColor) !important;
    border-color: var(--btn-primaryBorder) !important;
  }

  &:disabled {
    background-color: var(--buttonDisable);
    color: var(--disabledTextColor);
    box-shadow: none;
    border: none;
    opacity: 1;
  }
}

.btn-outline-primary {
  background-color: transparent;
  border-color: var(--btn-primaryColor);
  color: var(--btn-primaryColor);

  &:hover,
  &:focus,
  &.active {
    background-color: var(--btn-primaryColor) !important;
    border-color: var(--btn-primaryColor) !important;
    color: var(--btn-primaryTextColor) !important;
  }
}

.btn-secondary {
  background-color: var(--btn-secondaryColor);
  color: var(--btn-secondaryTextColor);
  border-width: 0 0 2px 0;
  border-color: var(--btn-secondaryBorder);

  &:hover,
  &:focus-visible,
  &:focus {
    background-color: var(--btn-secondaryHoverColor);
    color: var(--btn-secondaryHoverTextColor);
    border-color: var(--btn-secondaryBorder);
    box-shadow: none !important;
  }

  &:active {
    background-color: var(--btn-secondaryHoverColor) !important;
    color: var(--btn-secondaryHoverTextColor) !important;
    border-color: var(--btn-secondaryBorder) !important;
  }

  &:disabled {
    background-color: transparent;
    color: var(--disabledTextColor);
    box-shadow: none;
    border: none;
    opacity: 1;
    pointer-events: none;
  }
}

.btn-select {
  font-family: var(--regular);
  color: var(--primaryTextColor);
  background: var(--txtbox-enabled) url(../../images/icons/select-arrow.svg) no-repeat 97% 8px / 20px;
  height: 32px;
  min-width: 210px;
  max-width: 100%;
  text-align: left;
  box-shadow:
    0 -1px 1px var(--txtbox-border) inset,
    0 1px 0 #fff;
  border-width: 0;
  padding: 8px;

  @include ellipsis();

  &:hover {
    background-color: var(--txtbox-enabled-hover);
  }

  &:focus,
  &:active {
    background-color: var(--txtbox-enabled-hover) !important;
  }
}

.btn-disabled {
  color: var(--disabledTextColor);
  background-color: var(--buttonDisable);
  border-color: var(--buttonDisable);
  pointer-events: none;
}

.btn-lg {
  min-width: 210px;
  min-height: 50px;
  margin-top: 12px;
  font-family: var(--bold);
}

/* use this class for btn which have icon image */

.btn-icon {
  @include flex(null, null, center);

  padding-top: 6px;
  padding-bottom: 6px;

  img {
    margin-right: 10px;
  }
}

/* #######   Button group   ####### */

.btn-group {
  >.btn:not(:first-child) {
    margin-left: 16px !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  >.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.btn-center {
    display: block;
    text-align: center;
    padding-block: 8px;
  }
}

/* Button group 2 */

.type-2-btn-group {
  background-color: var(--coreGrayBackground);

  button {
    margin-right: 24px;
    border: none !important;
  }
}

.button-file {
  .btn.btn-primary {
    height: 32px;
    padding: 0;
    width: 95px;
    overflow: hidden;
    position: relative;
  }

  .form-control:not(.allow-edit) {
    pointer-events: none;
    overflow: hidden;
  }

  input[type='file'] {
    height: 100%;
    opacity: 0;
  }

  .file-text {
    position: absolute;
    top: 8px;
    width: 100%;
    text-align: center;
    pointer-events: none;
    left: 0;
  }
}