@use 'src/assets/styles/base/mixins' as *;

.mat-mdc-menu-panel,
.mat-mdc-elevation-specific {
  min-width: 150px !important;
  border-radius: 8px !important;
  box-shadow: var(--box-shadow) !important;

  .mat-mdc-menu-item {
    padding: 8px 12px !important;
    min-height: 30px !important;
    color: var(--secondaryTextColor) !important;

    .mat-mdc-menu-item-text,
    .mat-mdc-menu-item-text span {
      display: flex;
      gap: 12px;
      font-family: var(--medium) !important;
      font-size: var(--body-font-size) !important;
      line-height: var(--body-line-height) !important;
      letter-spacing: 0.035px !important;
    }

    &[disabled] {
      opacity: 1;

      .mat-mdc-menu-item-text {
        color: var(--disabledTextColor) !important;
      }
    }

    &:not([disabled]):hover {
      background-color: rgba(60, 102, 255, 0.05) !important;
    }
  }
}

.mat-icon {
  padding-top: 3px;
}

.mat-mdc-button {
  font-family: var(--regular) !important;
}

.mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-button-touch-target {
  padding: 0;
  width: 40px !important;
  height: 40px !important;
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  display: none !important;
}

.cdk-overlay-container {
  &:has(.index-2) {
    z-index: 2;
  }

  .menu-75vh {
    max-height: 75vh;
  }
}

@include media('down', sm) {
  .mat-mdc-menu-panel .mat-mdc-menu-item {
    padding: 5px 12px;
  }
}