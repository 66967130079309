ngx-gridster .gridster-container {
  height: 600px !important;
  width: 100% !important;
}

gridster .gridster-row {
  border-top: 1px solid var(--defaultGraySecondary) !important;
  border-bottom: 1px solid var(--defaultGraySecondary) !important;
}

gridster .gridster-column {
  border-left: 1px solid var(--defaultGraySecondary) !important;
  border-right: 1px solid var(--defaultGraySecondary) !important;
}