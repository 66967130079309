@use 'src/assets/styles/base/mixins' as *;

.mdc-switch {
  &:enabled {
    .mdc-switch__track {
      height: 15px !important;

      &::after {
        background: var(--switch-track-background) !important;
        border: 1px solid var(--switch-track-border);
      }
    }

    &:focus:not(:active) .mdc-switch__track::before,
    &:not(:focus):not(:active) .mdc-switch__track::before {
      background: var(--switch-track-background) !important;
      border: 1px solid var(--switch-track-border);
    }

    .mdc-switch__ripple {
      opacity: 0;
    }

    .mdc-switch__shadow {
      box-shadow:
        0 2px 1px -1px #0003,
        0 1px 1px #00000024,
        0 1px 3px #0000001f !important;
    }
  }

  &.mdc-switch--unselected:enabled {
    .mdc-switch__handle::after {
      border: 1px solid var(--switch-unselected-border);
      background: var(--switch-unselected-background) !important;
    }

    .mdc-switch__icon {
      fill: var(--secondaryTextColor) !important;
    }
  }

  &.mdc-switch--selected:enabled {
    .mdc-switch__handle::after {
      background: var(--switch-selected-background) !important;
      border: 1px solid var(--switch-selected-border);
    }
  }
}

.mdc-label {
  font-family: var(--regular);
}

/* toggle mat */

.mat-mdc-slide-toggle label {
  padding-left: 12px !important;
}