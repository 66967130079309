@use 'src/assets/styles/base/mixins' as *;

.editable {
  border: none !important;
  outline: none !important;
  background: transparent !important;
  position: absolute;
  left: 0;
  padding: 0 20px 0 0;
  top: 0;
  width: 100%;
  border-bottom: 2px solid var(--separatorColor) !important;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
  box-shadow: none !important;
  letter-spacing: 0 !important;

  &::placeholder {
    font-size: 24px;
    color: var(--disabledTextColor);
  }

  &:focus {
    border-bottom-color: var(--txtbox-focus-border) !important;
  }
}

.editable-heading {
  display: inline-flex;
  position: relative;
  min-width: 30vw;
  max-width: 75dvw;
  align-items: center;

  &.initial-title {
    min-width: auto;

    .editable {
      border-color: transparent;
    }
  }

  i-feather {
    margin-left: 5px;

    &.edit {
      margin-top: 0;
    }
  }
}

.editable-space {
  flex-grow: 1;
  visibility: hidden;
  min-height: 32px;

  @include ellipsis();
}

/* editable textarea for description */

.editable-desc {
  min-width: 250px;
  margin-bottom: 24px;

  textarea {
    min-height: 23px;

    &::placeholder {
      font-size: 14px;
      font-family: var(--regular);
    }
  }
}

/* --- ng editor --- */

.ng-editor * {
  font-family: var(--regular) !important;
}

.ng-editor {
  strong {
    font-family: var(--bold) !important;
  }
}

.ck.ck-editor__main>.ck-editor__editable {
  @extend .form-control;

  max-width: 100%;
  height: auto;

  &.ck-focused {
    color: #495057;
    border-color: var(--txtbox-focus-border) !important;
    box-shadow: 0 0 0 0.2rem rgb(34 107 252 / 25%) !important;
  }
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: var(--coreGrayBackground);
  border: 0;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: 0 0 8px 8px !important;
  max-height: 260px;
  height: 260px;
  border-color: transparent !important;
  background-color: var(--txtbox-enabled);

  &.ck-focused {
    border-color: var(--txtbox-focus-border) !important;
    background-color: var(--fgColor);
  }
}

.ck-editor:has(.ck-toolbar__items:empty) {
  // margin-top: 3px !important;

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-radius: 8px !important;
  }
}

.ck-button__label {
  font-family: var(--medium) !important;
  color: var(--secondaryTextColor) !important;
}

.editable-inline {
  display: flex;
  min-height: 50px;

  .editable {
    padding-right: 0;
  }

  p {
    margin-bottom: 0;
  }

  .editable-icons {
    padding: 7px 0 0 10px;
    display: flex;
    gap: 20px;
    color: var(--secondaryTextColor);
  }
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border-width: 0 !important;
}

.form-messageTemp {

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    min-height: 127px;
    height: auto;
  }

  .message-template-subject {
    textarea.form-control {
      min-height: 32px;
      max-height: 32px;

      p {
        margin-bottom: 0 !important;
      }
    }
  }
}

.ck-editor-no-header {
  .ck-reset_all {
    display: none;
  }
}

.editor-100 {

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 100px;
  }
}

app-text-editor.form-control {
  background-color: transparent;
  max-width: 100%;
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  &.is-invalid {
    padding-right: 0;
    border: none;

    .ck-editor__editable.ck-rounded-corners {
      border: 1px solid var(--negativeColor) !important;
    }
  }
}

:is(.initial-title) .editable-space {
  visibility: visible;
  padding: 0 0 8px 0;
  max-width: 100%;
  border-bottom: 2px solid transparent;
}

.question-editor .ck-content.ck-editor__editable p {
  margin-bottom: 5px !important;
}