@use 'src/assets/styles/base/mixins' as *;

/* Custom Theming for Angular Material */

/* For more information: https://material.angular.io/guide/theming */
@use '@angular/material' as mat;

/* Plus imports for other components in your app. */

/* Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once! */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
(imported above). For each palette, you can optionally specify a default, lighter, and darker
hue. Available color palettes: https://material.io/design/color/ */
$custom-primary: mat.define-palette(mat.$blue-palette, A700);
$custom-accent: mat.define-palette(mat.$gray-palette, 200);

/* The warn palette is optional (defaults to red). */
$custom-warn: mat.define-palette(mat.$red-palette);

/* Create the theme object. A theme consists of configurations for individual
theming systems such as "color" or "typography". */
$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn,
    ),
  )
);

/* Include theme styles for core and each component used in your app.
Alternatively, you can import and @include the theme mixins for each component
that you are using. */
@include mat.all-component-themes($custom-theme);

/* You can add global styles to this file, and also import other style files */

.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: var(--regular);
}

.mdc-evolution-chip__text-label {
  font-family: var(--regular) !important;
}

.date-icon .mat-focus-indicator::before {
  border-color: transparent !important;
}

.date-icon .mat-button-focus-overlay {
  display: none !important;
}

.date-icon .mat-button-focus-overlay:focus {
  display: none !important;
}

.date-icon .mat-mdc-input-element {
  background-color: var(--txtbox-enabled) !important;
  background-clip: padding-box;
  padding-left: 7px !important;
}

.vitals-detail-wrapper .vital-tabs .mat-tab-label-container .mat-tab-labels {
  padding-top: 12px;
  gap: 48px;
  margin-bottom: -10px;
}

.mat-expansion-indicator::after {
  margin-bottom: 5px !important;
}

.custom-tooltip {
  font-size: 12px;
  max-width: 118px !important;
}

.customTooltipStyle {
  text-align: center;
  background-color: var(--tooltipColor);
  position: relative;
  overflow: visible !important;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--tooltipColor);
  }
}

.time-picker-content {
  fieldset {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.ngb-tp-hour,
.ngb-tp-minute,
.ngb-tp-second,
.ngb-tp-meridian {
  gap: 4px;
}

/* mat-slider */

.mat-mdc-slider {
  width: 200px !important;
  height: 30px !important;

  .mdc-slider__track {
    border-radius: 100px;
    background: #fff;
    border-radius: 100px;
    border: 1px solid var(--separatorColor);
    height: 12px !important;
  }

  .mdc-slider__track--inactive {
    background-color: transparent !important;
    height: 12px !important;
  }

  .mdc-slider__track--active {
    height: 12px !important;
  }

  .mdc-slider__track--active_fill {
    border-color: var(--primaryColor) !important;
    border-top-width: 12px !important;
  }

  .mdc-slider__thumb {
    height: 25px !important;
    width: 25px !important;
    bottom: 3px;
    left: -12px !important;
    background-color: #fff !important;
    border: 1px solid var(--separatorColor);
    border-radius: 100%;

    &--focused {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
    }

    .mdc-slider__thumb-knob {
      background-color: transparent !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .mat-ripple {
      display: none !important;
    }
  }

  &.mdc-slider--disabled {
    opacity: 1 !important;

    .mdc-slider__input {
      cursor: auto;
    }
  }
}

/*  mat date calendar input */

.date-icon {
  position: absolute;
  right: 10px;
  left: initial;
  bottom: 1px;
  color: var(--secondaryTextColor);

  .mat-icon {
    color: var(--secondaryTextColor);
  }
}

.date-icon .mat-mdc-icon-button,
.date-icon .mat-mdc-button-touch-target {
  height: unset !important;
  width: unset !important;
}

.mat-mdc-icon-button {
  color: var(--secondaryTextColor) !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}

//remove this if tab removed form Assessment>Guidance & Alerts (sidebar)
.guidance-alert-container .mat-mdc-tab-group .mat-mdc-tab-list .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: transparent !important;
}

.guidance-alert-container
  .guidance-alert-tabs
  .mat-mdc-tab-group
  .mat-mdc-tab-list
  .mat-mdc-tab
  .mdc-tab-indicator__content--underline {
  border-color: var(--primaryColor) !important;
}
