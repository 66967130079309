@use 'src/assets/styles/base/mixins' as *;

.custom-typeahead {
  border-radius: 16px !important;
  padding: 14px 0;

  .mat-mdc-option {
    font-family: var(--regular);
    font-size: 12px;
    line-height: 2;
    height: auto;
    // padding: 4px 24px;
  }

  /* override the default styles to enabling multi-select */

  .multi-select-checkbox {
    padding: 0;

    .mdc-list-item__primary-text {
      display: flex;
      flex-grow: 1;

      .mat-mdc-checkbox {
        flex-grow: 1;
        // padding-block: 4px;
        // padding-inline: 24px;
      }

      label.mdc-label {
        flex-grow: 1;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}