@use 'src/assets/styles/base/mixins' as *;

.task-details-wrapper {
  display: grid;
  gap: 24px;
  word-break: break-word;
}

.activity-image {

  object,
  img {
    width: 100%;
    height: auto;
    background: var(--bgColor);
    aspect-ratio: 16/9;
    object-fit: contain;
  }
}

.add-resource-disabled .btn-primary {
  background-color: var(--buttonDisable);
  color: var(--disabledTextColor);
  box-shadow: none;
  border: none;
  pointer-events: none;
}

.mat-mdc-tab-body-content:has(.task-target-icon) {
  padding-block-start: 5px;
}