@use 'src/assets/styles/base/mixins' as *;

/* CSS for Inclusion/Exclusion Criteria table in tooltip */

.criteria-tooltip-table {
  .tooltip-inner {
    max-width: 800px;
    padding: 8px;
  }
}

/* for reach trials start */
.reach-tab {
  .acc-content {
    .tooltip-inner {
      max-width: 800px !important;
    }

    .tooltip.show {
      opacity: 1;
    }
  }

  .mat-mdc-tab-body-wrapper,
  .mat-tab-body.mat-tab-body-active,
  .system-tabs .mat-tab-body-content {
    overflow: visible !important;
  }

  .table-wrapper .table-responsive table {
    border: none;
  }

  table th.mat-mdc-header-cell,
  .mat-mdc-table {
    background-color: transparent;
  }

  .tooltip {
    z-index: 9999;
  }
}

.client-active {
  i-feather[name='reach-trial'],
  .reach-trial {
    background: url(../../images/icons/reach-trial-white.svg) no-repeat center 100%/100%;
  }
}
