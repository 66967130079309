@use 'src/assets/styles/base/mixins' as *;

.breadcrumb {
  font-family: var(--medium);
  letter-spacing: 0.004em;
}

.managed_accordion span {
  display: inline-block !important;
}

.user-status {
  .preferences-title-bar {
    @include flex(null, space-between);
  }

  .user-status-row {
    @include flex(column);

    gap: 16px;

    h3 {
      @include flex(row);

      margin-bottom: 0;
      gap: 24px;

      span {
        &:first-child {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
  }
}

.col-providers {
  .col-pvdr-row {
    @include flex(null, space-between);

    padding-bottom: 24px;
  }

  .pvdr-left {
    @include flex(null, center, center);
  }

  .p-icon-img {
    @include flex(null, center, center);

    width: 30px;
    margin-right: 20px;
  }
}

.user-count i-feather {
  color: var(--secondaryTextColor);
}

.managed_support .mat-expansion-panel-body {
  padding: 0 0 0 24px;
}

.ug-select {
  &.is-invalid {
    border: 1px solid var(--negativeColor);
    border-radius: 4px;
  }

  .mat-mdc-select-trigger {
    padding-inline: 0;
  }
}

.ug-select-panel {
  .mat-pseudo-checkbox {
    display: none;
  }

  mat-option.mat-mdc-option {
    &.mdc-list-item--selected {
      background-color: rgba(60, 102, 255, 0.05) !important;

      .mdc-list-item__primary-text {
        color: var(--secondaryTextColor) !important;
      }
    }

    &:hover {
      background-color: rgba(60, 102, 255, 0.05) !important;
      color: var(--secondaryTextColor) !important;
    }
  }
}
