@use 'src/assets/styles/base/mixins' as *;

@font-face {
  font-family: font-bold;
  src: url(../../fonts/Ubuntu-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: font-medium;
  src: url(../../fonts/Ubuntu-Medium.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: font-regular;
  src: url(../../fonts/Ubuntu-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
}

h1,
.h1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-family: var(--medium);
  color: var(--primaryTextColor);
}

h2,
.h2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-family: var(--bold);
  color: var(--primaryTextColor);
  letter-spacing: 0.0015em;
  margin-bottom: 0;
}

h3,
.h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: var(--bold);
  color: var(--secondaryTextColor);
}

h4,
.h4 {
  @include body-text(var(--bold));

  color: var(--primaryTextColor);
}

h6 {
  font-family: var(--regular);
  color: var(--disabledTextColor);
  letter-spacing: 0.0025em;
}

a {
  color: var(--linkTextColor);
  text-decoration: none;
}

:is(.card-base, .common-card, .cards-wrapper) h2 {
  color: var(--primaryColor);
}

/* class display css */

/* ---  display --- */

.display-1 {
  font-size: var(--display-1-font-size);
  line-height: var(--display-1-line-height);
  letter-spacing: -0.005em;
  font-family: font-medium, sans-serif;
}

.display-2 {
  font-size: var(--display-2-font-size);
  line-height: var(--display-2-line-height);
  letter-spacing: -0.005em;
  font-family: font-medium, sans-serif;
}

.display-3 {
  font-size: var(--display-3-font-size);
  line-height: var(--display-3-line-height);
  font-family: font-medium, sans-serif;
}

.display-4 {
  font-size: var(--display-4-font-size);
  line-height: var(--display-4-line-height);
  font-family: font-medium, sans-serif;
}