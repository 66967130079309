@use 'src/assets/styles/base/mixins' as *;

.form-group.form-group-mat-select {
  @include flex(column, null, flex-start);
}

.mat-mdc-select-panel {
  padding: 0 !important;
  border-radius: 8px !important;
  box-shadow: var(--box-shadow) !important;

  &:has(.form-group) {
    margin-top: 47px;
    width: 240px !important;
  }
}

.mat-mdc-option {
  color: var(--secondaryTextColor) !important;
  font-family: font-regular, sans-serif !important;
  font-size: var(--body-font-size) !important;
  line-height: var(--body-line-height) !important;
  height: auto;
  min-height: 33px !important;
  background-color: var(--fgColor) !important;
  margin: 8px 0;
  padding: 8px 16px !important;

  &.separator {
    position: relative;
    padding-top: 16px !important;
    margin-top: 16px !important;

    &::after {
      content: '';
      position: absolute;
      left: 16px;
      top: 0;
      width: calc(100% - 32px);
      height: 1px;
      background: var(--separatorColor, #ddd);
    }
  }

  .mdc-list-item__primary-text {
    margin-right: 0 !important;

    div {
      @include align-center(10px);

      img {
        @include size(37px);

        border-radius: 100%;
      }
    }
  }

  &:has(.mat-mdc-option-search) {
    padding-bottom: 0 !important;
  }

  .mat-pseudo-checkbox-minimal {
    color: var(--primaryTextColor);
  }

  span {
    @include ellipsis();
  }

  &:not(.mdc-list-item--disabled) {
    margin-block: 0;
    transition: 0.3s all;

    &.mdc-list-item--selected,
    &.mat-mdc-option-active {
      background-color: rgba(60, 102, 255, 0.05) !important;

      .mdc-list-item__primary-text {
        color: var(--secondaryTextColor) !important;
      }
    }

    &:hover {
      background-color: rgba(60, 102, 255, 0.05) !important;
    }

    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
      color: var(--primaryColor) !important;
    }
  }
}

/* mat option group */

.mat-mdc-optgroup {
  .mat-mdc-optgroup-label {
    font-size: var(--body-font-size) !important;
    line-height: var(--body-line-height) !important;
    font-family: font-regular, Arial, sans-serif !important;
    font-weight: bold !important;
    color: var(--secondaryTextColor) !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
    height: auto !important;
    min-height: auto !important;
  }

  &+.mat-mdc-optgroup {
    margin-top: 8px;
  }
}

.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-mdc-select-min-line {
  display: block;
}

.mat-mdc-select-min-line,
.mat-mdc-select-value-text,
.mat-mdc-select {
  font-family: font-regular, sans-serif;
  color: var(--primaryTextColor);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.mat-mdc-select-arrow-wrapper {
  height: auto !important;
}

.mat-mdc-select-min-line::first-letter,
.mat-mdc-select-value-text::first-letter,
.mat-mdc-option-text::first-letter {
  text-transform: uppercase;
}

/* for Custom arrow  */

.custom-select-arrow {
  mat-select.form-control {
    border: 1px solid transparent;
    box-shadow:
      0 -1px 1px var(--txtbox-border) inset,
      0 1px 0 #fff;
    border-radius: 8px;
  }

  .mat-mdc-select {
    display: flex;

    &.form-control:focus {
      @include input-focus();
    }
  }

  .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    width: 22px;
    height: 18px;
    border: 0;
    margin: 0;
    background: no-repeat center/100% url('../../images/icons/select-arrow.svg');

    svg {
      display: none;
    }
  }

  .mat-mdc-select-disabled {
    pointer-events: none;

    .mat-mdc-select-min-line {
      color: var(--disabledTextColor);
    }

    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
      background-image: url('../../images/icons/select-arrow-disabled.svg');
    }
  }
}

/* --- autocomplete --- */

.cdk-overlay-container:has(.mat-mdc-autocomplete-panel) {
  z-index: 2 !important;
}

.mat-mdc-autocomplete-panel {
  &.mdc-menu-surface {
    border-radius: 16px;
  }

  // .mat-mdc-option {
  //   min-height: 0;
  // }
}