@use 'src/assets/styles/base/mixins' as *;

.dialog-header-icons {
  display: flex;
  gap: 20px;

  i-feather {
    cursor: pointer;
  }
}

.add-medication-container {
  &.modal-xs {
    width: 280px;
  }

  &.dialog-md {
    @include media('down', md) {
      width: 650px;
    }
  }

  .mat-mdc-dialog-content {
    padding-block: 3px;
    max-height: calc(65vh - 70px);
  }

  .medication-header {
    border-bottom: 1px solid var(--separatorColor);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .medication-list:last-child {
    margin-bottom: 16px;
  }

  .add-medication-search {
    padding: 2.5px;
    color: var(--secondaryTextColor);
  }

  .medicine-name {
    color: var(--primaryTextColor);
  }

  .form-control {
    max-width: 100%;
    width: 206px;
  }

  .medication-form-icons {
    display: flex;
    gap: 29px;
    flex-wrap: wrap;
    max-width: 570px;
  }

  .medication-color {
    display: flex;
    gap: 20px;

    .medication-color-item {
      width: 43px;
      height: 43px;
    }
  }

  .inline-field-group {
    display: flex;
    gap: 16px;
    align-items: center;

    .count-input {
      width: 35px !important;
      padding-inline: 0;
      text-align: center;
      box-shadow:
        0 -1px 1px rgb(0 0 0 / 10%) inset,
        0 1px 0 #fff;
    }

    .btn-plus-minus {
      width: 32px;
      height: 32px;
      padding: 0;
      font-family: var(--bold);
      font-size: 16px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      margin-right: 0;
    }
  }

  .medication-detail {
    border-top: 1px solid var(--separatorColor);
    padding-top: 16px;
    margin-bottom: 16px;
  }
}
