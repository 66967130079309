@use 'src/assets/styles/base/mixins' as *;

.dashboard-wrap {
  @include flex;
}

.centerwidget {
  width: 50px;
}

.widgets-sidebar {
  min-height: 300px;
  max-height: 834px;
  overflow-y: auto;
}

.dashboard-view {
  overflow-y: auto;
}

.gridster-item-inner {
  height: 100%;
}

.widgets-sidebar {
  background-color: var(--fgColor);
  padding: 16px;
  width: 206px;
  margin-right: 24px;

  .widget {
    margin-bottom: 30px;

    .widget-inner {
      min-height: 86px;
      padding: 15px;
      background-color: var(--sidebar-widget-background);

      @include flex(column, center, center);
    }

    .widget-name {
      font-size: 10px;
      text-align: center;
      line-height: 16px;
    }
  }

  .widget-remove {
    .widget-inner {
      @include flex(column, center, center);
    }

    i {
      margin: 12px 0;
    }
  }

  .widget-form-fields {
    margin-bottom: 30px;
  }
}

.dashboard-view {
  background-color: var(--fgColor);
  border-radius: 16px;
  padding: 23px;
  flex-grow: 1;

  .logo-row {
    @include flex(null, null, center);
  }

  .db-logo {
    width: 111px;
    height: 53px;
  }

  .db-title {
    @extend .logo-row;
  }

  .sm-col {
    height: 100px;
  }

  .md-col {
    height: 250px;
  }
}

@include media('down', md) {
  .dashboard-wrap {
    flex-direction: column;
  }

  .widgets-sidebar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    margin-bottom: 24px;
  }

  .widget-form-fields .form-group {
    display: flex;
    align-items: center;

    label {
      padding-right: 5px;
    }
  }

  .widget {
    min-width: 30%;
  }
}

@include media('down', md) {
  .widget {
    width: 100%;
  }

  .sm-col {
    margin-bottom: 24px;
  }
}