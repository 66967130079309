@use 'src/assets/styles/base/mixins' as *;

@include scrollbar();

.communication-cards {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: auto;
  }
}

.replacements-list {
  padding-inline: 0 !important;
  color: var(--secondaryTextColor);
  padding-block: 20px;
  position: sticky;
  top: 84px;

  h4 {
    font-family: font-medium, sans-serif;
    padding: 0 16px 20px;
    border-bottom: 1px solid var(--separatorColor);
    color: var(--primaryTextColor);
  }
}

.list-with-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.list-with-icon-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 12px 16px;

  span {
    margin-left: 16px;
  }
}

.communication-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  .communication-card-icons {
    display: flex;
    gap: 20px;
    color: var(--secondaryTextColor);
  }
}

.communication-card-content {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form-group {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
    }
  }

  .info-view {
    padding-top: 10px;
    gap: 8px;

    p {
      margin-bottom: 0;
      font-size: 12px;
      padding-top: 2px;
    }
  }

  .communication-card-content-item {
    display: flex;
    gap: 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .communication-card-body {
    font-size: 14px;
  }

  .communication-card-label {
    min-width: 110px;
    text-align: right;
    font-weight: 400;
    font-family: 'font-bold', sans-serif;
    font-size: 16px;
    letter-spacing: -0.1px;
  }
}

.communication-temp-form {
  .form-control {
    max-width: 100%;
  }

  .form-communication {
    font-size: 14px;
    display: inline-block;
    min-width: 230px;

    .label-communication {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-size: 14px;
    }

    .cancel.btn-primary {
      background-color: #e7e7e7;
      border-color: #e7e7e7;
      color: var(--primaryTextColor);
    }

    .btn:disabled {
      background-color: #e7e7e7;
      border-color: #e7e7e7;
      color: var(--disabled);
    }

    .communication-btn {
      margin-top: 30px;
      float: right;
    }
  }
}

.hs-tab-card-title {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 16px;
  margin-bottom: 16px;
  gap: 20px;
}

.highlightTableColor {
  background-color: rgba(60, 102, 255, 0.05) !important;
}

.additional-alert {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondaryTextColor);
  padding-bottom: 16px;
  font-size: 12px;
}