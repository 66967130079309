
@use 'src/assets/styles/base/mixins' as *;

.active-indicator,
.inactive-indicator {
  width: 11px;
  height: 11px;
  min-width: 11px;
  min-height: 11px;
  border: 2px solid #fff;
  background-color: var(--positiveColor);
  position: absolute;
  border-radius: 50%;
  display: inline-block;
}

.inactive-indicator {
  background-color: var(--negativeColor);
}

.badge-indicator {
  position: relative;

  .active-indicator,
  .inactive-indicator {
    inset: auto -2px 6px auto;
  }
}

.btn-badge {
  position: relative;

  .active-indicator,
  .inactive-indicator {
    inset: auto -3px -3px auto;
  }
}

.badge-with-value {
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  font-family: var(--medium);
  color: #fff;
  border-radius: 50%;

  @include flex-center;

  &.gray-badge {
    background-color: var(--defaultGraySecondary);
    color: var(--secondaryTextColor);
  }
}