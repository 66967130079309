@use 'src/assets/styles/base/mixins' as *;
@use 'src/assets/styles/base/typography' as *;

.cdk-overlay-pane {
  @include scrollbar;

  height: initial !important;
}

.mat-mdc-dialog-container {
  min-width: 320px !important;

  .mdc-dialog__container {
    .mdc-dialog__content {
      @include body-text();

      color: var(--primaryTextColor);
      letter-spacing: 0.005em;
    }

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      box-shadow: var(--box-shadow) !important;
      max-height: 98vh !important;
      border-radius: 16px !important;
      padding: 20px 16px !important;
    }

    .mat-mdc-dialog-content {
      margin: 0 -16px;
      padding: 4px 16px;
      -webkit-overflow-scrolling: touch;
    }
  }

  .dialog-xl {
    width: 1500px;
    max-width: 100%;
  }

  .dialog-lg {
    width: 1000px;
  }

  .dialog-md {
    width: 800px;
  }

  .dialog-sm {
    width: 600px;
    max-width: 100%;
  }

  .dialog-xs {
    width: 400px;
  }

  .dialog-xxs {
    width: 300px;
  }
}

.max-height-90 .mat-mdc-dialog-container .mdc-dialog__container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    max-height: 90vh !important;
  }
}

.modal-base {
  background-color: var(--fgColor);
  border-radius: 16px;
  padding: 17px 24px;
  box-shadow: var(--box-shadow);
}

.roles-modal {
  @extend .modal-base;

  position: relative;

  i {
    position: absolute;
    font-size: 18px;
    inset: 0 0 auto auto;
    cursor: pointer;
  }

  .roles-modal-chkbox {
    border: 1px solid #2c2c2c;
    padding: 21px 18px;
  }
}

.main-navigation-dialog {
  .mat-mdc-dialog-container {
    min-width: 250px !important;

    .mat-mdc-dialog-content {
      max-height: calc(100dvh - 115px);
      margin-inline: 0;
      padding-inline: 0;
    }
  }
}

.client-dialogbox .mat-mdc-dialog-container {
  border-radius: 0;
  max-width: 550px;

  .card {
    width: 100%;
    margin: 0 0 4% 0;

    &:focus-visible,
    & > div:focus-visible {
      outline: none;
    }
  }
}

.sorting-dialogbox {
  .mat-mdc-dialog-container {
    padding: 8px 0;
    min-width: 261px;
  }
}

.program-dialogbox {
  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 16px;

    .roles-modal {
      padding: 20px 24px;
    }

    button {
      display: block;
      margin: auto;
    }
  }
}

.mat-mdc-dialog-title {
  @extend h2;
}

.custom-modalbox .mat-mdc-dialog-container {
  display: block;
  padding: 20px 15px;
  border-radius: 16px;
}

/* search and filter dialog */

.search_dialog,
.filter_dialog {
  .mat-mdc-dialog-content {
    max-height: calc(65vh - 250px);
  }
}

.managed_dialog {
  min-width: 500px;
  max-width: 600px;

  // .form-check {
  //   margin-bottom: 25px;
  // }

  .form-check label {
    margin: 0 0 0 12px;
    font-size: 14px;
    line-height: 14px;
  }

  .scrolling_sec {
    display: block;
    min-height: 200px;
    max-height: 300px;
    overflow-x: auto;
    padding: 4px 10px 0 4px;
    color: var(--secondaryTextColor);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e7e7e7;
    border-radius: 100px;
  }

  // .search_dialog {
  //   box-shadow:
  //     0 -1px 0.5px rgb(0 0 0 / 10%) inset,
  //     0 1px 0 #fff;
  //   width: 144px;
  //   height: calc(1.4em + 1rem + 2px);
  //   padding: 0.5rem 0.5rem;
  //   font-size: 0.85rem;
  //   font-weight: 400;
  //   line-height: 1.4;
  //   color: #9f9f9f;
  //   background-color: #e7e7e7;
  //   background-clip: padding-box;
  //   border: 1px solid rgba(0, 0, 0, 0.1);
  //   border-radius: 8px;
  //   transition:
  //     border-color 0.15s ease-in-out,
  //     box-shadow 0.15s ease-in-out;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;

  //   input {
  //     background: no-repeat;
  //     border: none;
  //     box-shadow: none;
  //     max-width: 100%;

  //     &::placeholder {
  //       font-family: var(--regular);
  //       font-size: 14px;
  //     }
  //   }
  // }
}

.add-medication-dialog,
.search-medication-dialog {
  .mat-mdc-dialog-container {
    box-shadow: var(--box-shadow);
    border-radius: 16px;
  }
}

@mixin mt-dialog-container {
  border-radius: 16px;
  padding: 17px 24px;
  box-shadow: none;
}

.custom-dialog {
  .mat-mdc-dialog-container {
    @include mt-dialog-container;

    min-width: 500px;
    max-width: 600px;
  }
}

.confirmation-dialog {
  .mat-mdc-dialog-container {
    @include mt-dialog-container;

    min-width: 320px;
    max-width: 400px;
  }
}

.search-filter-dialog {
  .mat-mdc-dialog-container {
    @include mt-dialog-container;
  }
}

.dialog-header {
  border-bottom: 1px solid var(--separatorColor);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.client-dropdown-dialog .mat-mdc-dialog-container {
  max-height: calc(98vh - 140px) !important;
  padding: 16px;
  box-shadow: var(--box-shadow);
}

.dialog-header-icons i-feather {
  color: var(--secondaryTextColor);
}

/* clientDisclaimerDialog */

.clientDisclaimerDialog .disclaimer-dialog {
  width: 850px !important;

  .add-disclaimer {
    padding: 0;
    border: none;
  }
}

html.cdk-global-scrollblock:has(.remove-scrollblock) {
  top: initial !important;
  left: initial !important;
  position: static !important;
  overflow: hidden !important;
}
