$primaryColor: #5166b1;
$black: #000;
$accent: #eaeaea;
$red: #e71d36;
$disabled: rgba(0, 0, 0, 0.32);
/* {##################   Bootstrap Customization   ###################} */
/*  Spinners  */
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;
$spinner-animation-speed: 0.75s;
$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;
/*  Spinners end  */
/* #######   Body   ####### */
$body-bg: #f8f8f8;
$body-color: $primaryColor;
$font-family-base: var(--regular);
$font-size-base: 0.85rem;
$line-height-base: 1.2;
/* #######   Links   ####### */
$link-color: $primaryColor;
$link-hover-decoration: none;
/* #######   Headings   ####### */
$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base * 1.15;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;
$headings-font-family: var(--bold);
$headings-font-weight: 400;
$headings-line-height: 1.2;
$display1-size: 2.2rem;
$display1-weight: 400;
/* #######   Border radius   ####### */
$border-radius: 8px;
/* #######   Form   ####### */
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.5rem;
//input
$input-bg: #e7e7e7;
$input-border-color: rgba(0, 0, 0, 0.1);
$input-line-height: 1.2;
$input-placeholder-color: #a4a4a4;
$input-focus-border-color: rgba($primaryColor, 0.8);
$input-padding-y: 0.45rem;
$input-padding-x: 0.75rem;
$input-focus-bg: var(--fgColor);
//group
$form-group-margin-bottom: 16px;
$input-group-addon-bg: transparent;
//checkobx
$form-check-input-gutter: 0rem;
//button
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
/* #######   margin, padding   ####### */
$spacer: 1rem;
$spacers: (
  0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer * 1.25,
  4: $spacer * 1.5,
  5: $spacer * 3,
  24: 24px,
  48: 48px,
);
/* #######   Cards   ####### */
$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;
$card-title-spacer-y: calc($spacer / 2);
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: 0.75rem;
$card-cap-padding-x: 1.25rem;
$card-cap-bg: transparent;
$card-bg: var(--fgColor);
$card-img-overlay-padding: $spacer;
/* #######   Breadcrumbs   ####### */
$breadcrumb-font-size: 12px;
$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.25rem;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $black;
$breadcrumb-active-color: $black;
$breadcrumb-divider: quote('>');
$success: $green;
$info: rgba($primaryColor, 0.15);
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
$purple: $purple;
$theme-colors: (
);
$theme-colors: map-merge(('primary': $primaryColor,
      'secondary': $secondary,
      'success': $success,
      'info': $info,
      'warning': $warning,
      'danger': $danger,
      'light': $light,
      'dark': $dark,
      'accent': $accent,
      'disabled': $disabled,
    ),
    $theme-colors);