@use 'src/assets/styles/base/mixins' as *;

.filter-bar {
  @include flex(null, space-between, center);

  padding-top: 0;

  .filter-bar-right {
    @include flex(null, space-between, center);
  }
}

.filter-box {
  .filter-label {
    font-family: var(--medium);

    span {
      padding-right: 10px;
      color: var(--secondaryTextColor);

      &.dropdown {
        padding-right: 0;
        cursor: pointer;
      }
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.client-content-wrap {
  @include flex(null, null, flex-start);

  @include media('down', md) {
    flex-direction: column;
  }
}

.client-right-col {
  background-color: var(--fgColor);

  .all-client {
    font-size: 16px;
  }

  .client-status {
    font-size: 12px;

    .status-label {
      font-family: var(--medium);
      padding: 0 4px;
    }
  }
}

/* Add clients page */

form.user-form {
  width: 55%;
}

.u-right-col {
  width: calc(45% - 24px);
}

.clientDisclaimerDialog {
  .mat-mdc-dialog-container {
    overflow-x: hidden;

    .disclaimer-dialog {
      width: 700px;

      .row {
        .col-md-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.clients-list-table {

  table th.mat-mdc-header-cell,
  table td.mat-mdc-cell,
  table td.mat-mdc-footer-cell {
    min-width: 125px;
  }

  .mat-column-Description {
    .head-in {
      @include line-clamp();
    }
  }
}

/* responsive */

@include media('down', sm) {
  .filter-bar {
    gap: 10px;

    @include flex(column, center, null);

    .filter-bar-right {
      gap: 10px;

      @include flex(column, center, null);
    }
  }
}