@use 'src/assets/styles/base/mixins' as *;

.view-detail-row {
  @include flex(column);

  gap: 16px;

  .view-detail-item {
    @include flex();

    gap: 24px;
    font-size: 16px;
    color: var(--primaryTextColor);
  }

  .view-detail-label {
    text-align: right;
    min-width: 150px;
  }
}

/* for global centered label and value presentation */

.label-value-list {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
  color: var(--primaryTextColor);

  &:last-child {
    margin-bottom: 0;
  }

  .list-label {
    text-align: right;
    flex-basis: 40%;
  }

  .list-value {
    font-family: font-regular, sans-serif;
    flex-basis: 60%;

    a {
      color: var(--primaryColor);
    }
  }
}

/* ngb dropdown */

.dropup {
  .dropdown-toggle::after {
    border-bottom-color: transparent;
  }
}

.ngb-more-dropdown {
  box-shadow: var(--card-box-shadow);
  border: none;

  &.right {
    right: 0 !important;
    left: initial !important;
  }

  .dropdown-item {
    @include body-text;
    @include align-center;

    border-radius: 0;
    white-space: normal;
    padding: 5px 5px 5px 20px;

    &:active {
      color: inherit;
      background-color: inherit;
    }

    &.ngb-dropdown-close {
      padding: 0;
      position: absolute;
      inset: 5px 10px 0 auto;
    }

    &:focus {
      border: none;
    }

    i-feather {
      position: absolute;
      left: 24px;
    }

    .ngb-dropdown-text {
      display: inline-block;
      padding-left: 32px;
    }
  }
}

/* page global sections */

#site-wrapper {
  min-height: 55vh;
  padding: 0 0 45px;
}

/* global, don't apply page specific style */
.page-header {
  padding: 16px var(--padding-inline);
  background-color: var(--page-header-background);

  &:not(:has(app-client-module-title)) {
    h1 {
      margin-bottom: 0;
    }

    nav {
      padding-top: 8px;
    }
  }

  .error {
    margin-block: 8px;
    color: var(--negativeColor);
  }

  /* remove card titlebar class once ui update and if it is not required */
  .card-titlebar {
    padding-bottom: 0;
  }
}

/* add if buttons availabe in page header, Ex.- add roles */
.page-header-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;

  .btn-group {
    justify-content: flex-end;
    align-items: center;

    .btn {
      flex: 0;
      white-space: nowrap;
    }
  }
}

/* to contain page title and breadcrumb, ex - edit roles etc. */
.page-header-left-wrapper {
  display: flex;
  position: relative;
  align-items: flex-end;

  .editable-inline,
  h1:not(.editable-heading) {
    position: absolute;
    left: 56px;
    top: 4px;
    max-width: 75dvw;

    @include ellipsis();
  }
}

/* global, don't apply page specific style */
.page-content-wrapper {
  padding: 16px var(--padding-inline);
}

/* No record */
.norecord {
  text-align: center;
  min-height: 40px;
  padding: 12px 8px;
  color: var(--disabledTextColor);
  background: var(--fgColor);
  margin-bottom: 0;
}