@use 'src/assets/styles/base/mixins' as *;

.guidance-alert-tabs {
  .mat-mdc-tab-labels {
    border-bottom: 1px solid var(--separatorColor);
  }

  .mat-mdc-tab-list {
    flex-grow: 1 !important;
  }

  .guidance-alert-inner-tabs .mat-mdc-tab-list {
    flex-grow: 0 !important;
  }

  .guidance-alert-inner-tabs {
    .mat-mdc-tab-labels {
      border-bottom: none;
    }
  }

  .ck.ck-toolbar.ck-toolbar_grouping {
    width: fit-content;
  }

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 110px;
    margin: 0;
    width: 100%;
  }
}

.btn-light-primary {
  width: 50px;
  font-size: 12px;
  background-color: rgba(60, 102, 255, 0.15);
  color: var(--secondaryTextColor);
  font-family: var(--regular);
  border-width: 0;

  @include flex-center;

  &:hover,
  &:focus,
  &.active {
    background-color: rgba(60, 102, 255, 0.2) !important;
    outline: none;
    box-shadow: none;
  }
}

.guidance-alert-tabs {
  .guidance-alert-rule-label {
    padding-inline: 12px;
    padding-bottom: 10px;
  }

  .guidance-alert-rule-content {
    padding-top: 16px;
    padding-inline: 3px;
  }

  .gray-badge {
    font-family: var(--medium);
    margin-left: -6px;
    font-size: 12px;
    margin-top: -9px;
  }
}

.add-resource-list {
  display: flex;
  flex-direction: column;
  color: var(--secondaryTextColor);
  padding-top: 8px;

  .add-resource-item {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    padding-block: 16px;

    .add-resource-item-inner {
      @include align-center(8px);
    }
  }
}

.programs-tooltip {
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
  text-align: left;
}

.form-full-width {
  .form-control {
    max-width: 100%;

    &.label_file {
      max-width: 206px;
    }
  }
}

.view-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  letter-spacing: 0.08px;

  p {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.guided-session-row {
  .common-card {
    height: calc(100% - 24px);

    .card-titlebar {
      justify-content: space-between;
    }
  }
}

.guided-session-list {
  @include flex-column(30px);
}

.guided-session-list-header {
  @include align-center(16px);

  justify-content: space-between;
  background-color: var(--primaryColor);
  color: var(--textColorOnPrimary);
}

.guided-session-list-header-schedule {
  @include align-center(16px);

  i-feather[name='drag-handle-white'] {
    margin-top: -6px;
  }
}

.guided-session-list-header-icons {
  @include align-center(16px);

  i-feather {
    cursor: pointer;
  }
}

.schedule-day-time {
  @include flex-column;

  strong,
  span {
    @include line-clamp(1);
  }
}

.guided-session-list-body {
  @include flex-column;

  .guided-session-sub {
    @include align-center(10px);
  }
}

.guided-session-list-item {
  @include flex-column;

  .guided-session-list-body {
    border: 1px solid var(--separatorColor);
    border-top: none;
  }
}

.guided-session-inner {
  @include flex(row, space-between, center);

  gap: 10px;
  padding: 16px;
  color: var(--secondaryTextColor);

  &:hover,
  &.active {
    background-color: rgba(60, 102, 255, 0.05);
  }

  .feather-20 {
    min-width: 20px;
  }
}

.guided-session-sub-label {
  @include line-clamp(1);

  font-family: var(--regular);
}

.all-day-trigger .mdc-form-field {
  display: initial;
}

.addvitals-info {
  display: flex;
  align-items: baseline;
  gap: 4px;
  padding-bottom: 20px;
  color: var(--secondaryTextColor);
}

.vital-list {
  .form-check {
    padding-block: 16px;
  }
}

.add-language-tab-content {
  padding: 3px;

  .btn-primary {
    &:disabled {
      @include buttonDisabled(1);

      .language {
        background-image: url('../../images/icons/translate-disabled.svg');
      }
    }
  }
}

.question-set-parent.qa-input-disabled {
  .form-control {
    pointer-events: none;
  }

  .input-with-icon {
    color: var(--disabledTextColor);

    &:hover {
      background-color: var(--txtbox-enabled);
    }
  }

  .button-file .btn-primary {
    pointer-events: none;
    background-color: var(--txtbox-enabled);
    color: var(--disabledTextColor);
    border-color: var(--txtbox-enabled);
  }
}

.mat-mdc-checkbox-checked.mat-checkbox-disabled {
  .mat-mdc-checkbox-frame {
    @include checkboxDisabled();
  }
}

/* guided session expansion panel */

.mat-expansion-panel.guided-session-list-item {
  box-shadow: none !important;
  border-radius: 0 !important;

  &:not(:last-child) {
    margin-bottom: 30px !important;
  }

  .mat-expansion-panel-header {
    padding-inline: 0 !important;
    font-size: 14px;
    font-family: var(--regular);
    cursor: default;
    height: auto !important;

    .mat-expansion-panel-header-inner {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 14px;
      justify-content: space-between;
      background-color: var(--primaryColor);
      color: var(--textColorOnPrimary);
      flex-grow: 1;
    }

    i-feather[name='chevron-down'] {
      display: block;
      cursor: pointer;
    }

    i-feather[name='chevron-up'] {
      display: none;
      cursor: pointer;
    }

    &.mat-expanded {
      height: auto;

      i-feather[name='chevron-up'] {
        display: block;
      }

      i-feather[name='chevron-down'] {
        display: none;
      }
    }

    .mat-content.mat-content-hide-toggle {
      margin-right: 0;
    }
  }

  .guided-session-list-header {
    flex-grow: 1;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-content {
    font-size: 14px;
    font-family: var(--regular);
  }

  .mat-expansion-panel-spacing {
    margin-block: 0;
  }
}

//

.question-item {
  .mat-expansion-panel-header {
    i-feather[name='chevron-down'] {
      display: inline-block;
      cursor: pointer;
    }

    i-feather[name='chevron-up'] {
      display: none;
    }

    &.mat-expanded {
      height: auto;

      i-feather[name='chevron-up'] {
        display: inline-block;
        cursor: pointer;
      }

      i-feather[name='chevron-down'] {
        display: none;
      }
    }
  }

  .qa-toggle {
    padding-block: 16px;
    margin-left: 10px;
  }
}

// .mat-accordion .mat-expansion-panel.guided-session-list-item {
//   box-shadow: none;

//   &:not(:last-child) {
//     margin-bottom: 30px;
//   }

//   &:first-of-type,
//   &:last-of-type {
//     border-radius: 0;
//   }
// }

/* guided session expansion panel end */

/* question set library */

.copy-qa-library {
  .common-card {
    box-shadow: none;
    padding: 0;

    .card-header-icons {
      display: none;
    }
  }
}

/* question listing */

.question-set-parent {
  // --pd: 16px;
  // --pl-children: 30px;

  .question-set {
    color: var(--secondaryTextColor);
    padding-inline: 16px;
  }
}

.qa.question-item {
  &.active {
    opacity: 1;
  }

  .mat-expansion-panel-header:not([aria-disabled='true']) {
    cursor: auto;
  }

  .mat-content.mat-content-hide-toggle {
    justify-content: initial;
  }

  .form-control {
    width: 270px;
  }

  textarea.form-control {
    width: 375px;
  }
}

.qa.question-item.qa-disabled {
  color: var(--disabledTextColor);
  pointer-events: none;

  .question-item-inner,
  label {
    color: var(--disabledTextColor);
  }

  i-feather,
  .input-with-icon,
  input[type='radio'],
  input[type='checkbox'] {
    opacity: 0.4;
  }
}

.qa .question-item-inner {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-block: 16px;
  color: var(--secondaryTextColor);

  &:has(i-feather[name='drag-handle']) {
    cursor: move;
  }

  i-feather[name='bar-chart-2'] {
    cursor: default;
  }

  .form-check {
    align-items: flex-start;
    margin-bottom: 0;
  }

  .question-text {
    line-height: 22px;
    // padding-top: 3px;
    // min-height: 23px;
  }
}

.question-text {
  span p {
    display: inline;
  }

  .qa-chart {
    margin-left: 10px;
  }

  .qa-edit {
    margin-left: 10px;
    // margin-top: -2px;
  }
}

.question-attachment {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  border: 1px solid var(--separatorColor);
  justify-content: center;

  @include align-center;
}

// .hover-plus {
//   i-feather[name='plus'] {
//     visibility: hidden;
//   }

//   &:hover {
//     i-feather[name='plus'] {
//       display: inline-block;
//       visibility: visible;
//     }
//   }
// }

// .hover-edit {
//   i-feather[name='edit-2'] {
//     display: none;
//   }

//   &:hover {
//     i-feather[name='edit-2'] {
//       display: inline-block;
//     }
//   }
// }

.label-mh {
  min-height: 21px;
  margin-bottom: 0;
}

.inline-label-container {
  .label-mh {
    display: inline;

    &~i-feather {
      margin-left: 10px;
    }
  }
}

.isu {
  font-family: var(--bold);
  font-style: italic;
  text-decoration: underline;
}

.drag-handle {
  margin-top: -2px;
}

.attachment-inline {
  position: relative;

  .button-file {
    // &:has(.selected-file) {
    //   padding-left: 56px;
    // }

    .label_file {
      width: 200px;
    }
  }

  .selected-file {
    @include size(48px);

    position: absolute;
    left: 0;
    top: 8px;
    margin: 0;
    padding: 0;
  }
}

.add-type-picklist:has(.selected-file) {
  .button-file {
    padding-left: 56px;
  }
}

.picklst-img-icon {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

.question-set-children {
  padding-left: 30px;
}

.add-question-trigger {
  display: flex;
  gap: 8px;
}

.add-question-trigger-icon {
  color: var(--secondaryTextColor);
  cursor: pointer;
}

.question-body-chip {
  .mdc-evolution-chip__cell--primary {
    @extend .form-control;

    padding-block: 0;
  }

  .mdc-evolution-chip__text-label {
    font-family: var(--regular) !important;
  }

  .mdc-evolution-chip__graphic {
    padding: 0 !important;
  }

  .clip-Prog {
    display: flex !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    max-width: 240px;
  }

  .resource-clip>div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mat-mdc-chip-focus-overlay {
    opacity: 0 !important;
  }
}

.common-tags {
  .mdc-evolution-chip__text-label {
    font-family: var(--regular) !important;
    font-size: 12px !important;
    color: var(--secondaryTextColor) !important;
  }

  .mdc-evolution-chip__graphic {
    padding: 0 !important;
  }

  .clip-Prog {
    display: flex !important;
    padding: 9px 8px !important;
    margin-bottom: 0 !important;
    max-width: unset;
    background-color: rgba(60, 102, 255, 0.15) !important;
    border-radius: 8px !important;
    margin-right: 0 !important;
  }

  .resource-clip>div {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .mat-mdc-chip-focus-overlay {
    opacity: 0 !important;
  }

  .mat-mdc-chip-remove {
    padding-right: 0 !important;
    color: var(--secondaryTextColor) !important;
    opacity: 1 !important;
  }
}

.question-set-container {
  .card-titlebar {
    position: relative;
    padding-bottom: 0 !important;
    z-index: 2;

    .btn {
      position: absolute;
      top: 0;
    }
  }

  .mat-mdc-tab-header {
    padding-right: 90px;
  }
}