@use 'src/assets/styles/base/mixins' as *;

.calendar-view {
  width: fit-content;
  background-color: var(--coreGrayBackground);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;

  .week-view {
    @include flex(null, flex-start);

    gap: 14px;
    flex-wrap: wrap;
    max-width: 266px;

    .date {
      @include flex(null, center, center);

      width: 26px;
      height: 26px;
      flex-shrink: 0;
      text-align: center;
      font-family: 'font-regular', sans-serif;
      color: var(--secondaryTextColor);
      border-radius: 50%;
      cursor: pointer;

      &.active {
        background-color: var(--primaryColor);
        color: var(--textColorOnPrimary);
      }
    }
  }
}

.month-view {
  .month-row {
    @include align-center(8px);

    max-width: 280px;
    flex-wrap: wrap;

    .month-tab {
      @include flex(null, center, center);

      width: 40px;
      height: 27px;
      background: var(--dove-gray);
      color: var(--disabledTextColor);
      padding: 0 10px;
      gap: 10px;
      border-radius: 8px;
      margin-bottom: 0;
      cursor: pointer;

      &.active {
        background-color: var(--primaryColor);
        color: var(--textColorOnPrimary);
      }
    }
  }
}

.calender-card-info {
  margin-bottom: 24px;
  font-size: 16px;
}

.calendar-slide-toggle {
  @include align-center(24px);

  font-size: 16px;
  margin-bottom: 24px;
}

.calendar-schedule {
  @include flex(column);

  .calendar-schedule-item {
    @include align-center(24px);

    height: 36px;
  }

  strong {
    font-size: 16px;
    text-align: right;
    min-width: 300px;
  }
}

.business-hrs {
  @include align-center(16px);

  span {
    color: var(--secondaryTextColor);
  }
}

/*  #######   Mat Calendar   #######  */

.mat-calendar {
  font-family: var(--regular);

  span,
  td {
    font-family: var(--regular) !important;
  }
}

.mat-calendar-body-today.mat-calendar-body-selected,
.mat-calendar-body-selected {
  background-color: var(--primaryColor) !important;
  color: var(--textColorOnPrimary) !important;
}