@use 'src/assets/styles/base/mixins' as *;

.documents-list-item {
  padding-block: 11px;
}

.trials-block {
  .documents-list-item {
    padding-block: 8px;
    color: var(--secondaryTextColor);
  }

  &.sites {
    .form-group {
      margin-bottom: 0;
      padding-block: 16px;
    }

    .active {
      font-family: var(--medium);
      border-bottom: 1px solid var(--separatorColor);
    }
  }
}

.trials-detail-block {
  h3 {
    display: flex;
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    span.details-label {
      min-width: 150px;
      display: inline-block;
      margin-right: 24px;
      text-align: right;
    }
  }
}

.mat-expansion-indicator::after {
  margin-bottom: 5px;
}

.criteria-item {
  ul {
    li {
      margin-bottom: 8px;
    }
  }
}