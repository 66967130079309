@use 'src/assets/styles/base/mixins' as *;

.header-left {
  display: flex;
  align-items: center;
  gap: 22px;

  .icon-buttons {
    display: flex;
    gap: 22px;

    .btn {
      width: 66px;
      height: 32px;
      padding: 4px;
      border: 0;

      &.btn-trigger {
        background-color: var(--menu-trigger-background);
        color: var(--menu-trigger-color);
      }
    }

    i-feather[name='x'] {
      display: none;
    }

    .close-menu {
      i-feather[name='x'] {
        display: inline-block;
      }

      i-feather[name='menu'] {
        display: none;
      }
    }
  }

  .header-logo {
    img {
      width: 160px;
    }
  }
}

.header-right {
  display: flex;

  .hr-col {
    @include flex-center();

    padding-left: 22px;

    &:not(:last-child) {
      padding-right: 22px;
      border-right: 1px solid #c5c5c5;
    }
  }

  .login-user {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// /* search start */

// .input-search {
//   @extend .form-control;

//   padding: 0;

//   .input-group-text {
//     border: none;
//     padding: 2px 8px;
//     background-color: transparent;
//   }

//   i-feather[name='search'] {
//     width: 20px;
//     height: 20px;
//     color: var(--disabledTextColor);
//   }
// }

// .input-group.input-search {
//   display: flex;
//   background-color: var(--txtbox-enabled);
//   border-radius: 8px;
//   width: auto;

//   .form-control {
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     margin-top: -1px;

//     &:focus {
//       background-color: var(--fgColor);
//       border-color: rgba(81, 102, 177, 0.8);
//     }
//   }
// }

// /* header search end */

.user-group {
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: 0.3s all;

  .user-group-dropdown {
    background-color: var(--fgColor);
    border-radius: 8px;
    box-shadow: var(--card-box-shadow);
    position: absolute;
    width: 100%;
    right: 0;
    top: calc(100% + 10px);
    display: none;
    min-width: 150px;

    &::before {
      height: 10px;
      width: 100%;
      position: absolute;
      top: -10px;
      left: 0;
      content: '';
    }

    a {
      display: block;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);

    .user-group-dropdown {
      display: block;
    }
  }
}

/* header sticky */

app-header {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  transition: 0.3s;
  background-color: var(--bgColor);
}

.view-all-client.mat-mdc-menu-panel {
  max-width: 210px;

  .mat-mdc-menu-content .mat-mdc-menu-item {
    word-break: break-all;
  }
}

/* #######   responsive   ####### */

@include media('down', md) {
  header>div {
    margin-bottom: 10px;
  }
}