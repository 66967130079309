@use 'src/assets/styles/base/mixins' as *;
@use 'src/assets/styles/form/input' as *;

/* paginator */

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-select-trigger {
  @include body-text;
}

.mat-mdc-paginator {
  background-color: transparent !important;
  padding-top: 10px;

  .mat-mdc-select {
    @extend .form-control;

    min-height: 0;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-infix {
      color: var(--textColorOnPrimary);
      background-color: var(--primaryColor);
      padding-inline: 0 !important;
      padding: 0.2em 2px !important;
      min-height: auto;
      border-radius: 8px;
      border-top: 0 solid transparent;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);

      .mat-mdc-select-min-line {
        color: var(--textColorOnPrimary);
        font-size: var(--table-font-size);
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
      border: none !important;
    }

    .mat-mdc-form-field-flex {
      border-radius: 0;
      padding: 0;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(0);
    }
  }

  .mat-mdc-paginator-page-size-select {
    margin: 0;
    width: 56px;
  }

  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    width: 15px;
    height: 15px;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-top: 0 solid;
    margin: 0 4px;
    background: no-repeat center/70% url('../../images/icons/white-angle-down-solid.svg');
  }
}

.mtable .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  background: no-repeat center/70% url('../../images/icons/gray-angle-down-solid.svg');
}

.mat-mdc-paginator-page-size,
.mat-mdc-paginator-range-label {
  @include body-text;

  color: var(--secondaryTextColor) !important;
}

.mat-mdc-paginator-outer-container .mat-mdc-select {
  background-color: transparent !important;
  padding: 0 0 0 10px;
  box-shadow: none;
  border: 0;
  height: auto;

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  .mat-mdc-select-value {
    color: var(--textColorOnPrimary);
  }

  .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
    display: none;
  }
}

@mixin table-cell() {
  font-size: var(--table-font-size) !important;
  line-height: var(--table-line-height) !important;
  color: var(--table-textColor) !important;
  min-width: 180px;
  max-width: 450px;
  word-break: break-word;
  padding: 3px 16px;
  border-right-color: var(--separatorColor) !important;
  border-right-width: 1px;
  border-bottom-width: 0;
}

table {
  &.mat-mdc-table {
    font-family: var(--table-font-family) !important;
    width: 100%;
  }

  .mat-mdc-header-row {
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  tr.mat-mdc-row {
    font-family: var(--table-font-family) !important;

    &:hover {
      background-color: var(--table-row-hover) !important;
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    @include table-cell();
  }

  .back-lightBlue,
  .mat-column-alert,
  .mat-column-highlight,
  .mat-column-templates,
  .cdk-column-keywordHighlight,
  .cdk-column-messageTemplates {
    background-color: var(--bgHighlight) !important;
  }

  th.mat-mdc-header-cell {
    font-family: var(--table-header-font-family) !important;
    font-size: var(--table-font-size) !important;
    line-height: var(--table-line-height) !important;
    background-color: var(--table-header-background) !important;
    color: var(--table-header-textColor) !important;
    position: relative;
    font-weight: 400;

    &:first-of-type {
      padding-left: 16px;
    }

    &.active-cell {
      background: var(--table-header-sorted-background) !important;
    }

    .tbl-th {
      cursor: pointer;
    }

    .tbl-sort {
      display: inline-flex;
      flex-direction: column;
      position: absolute;
      top: -4px;
      right: 22px;
      height: 100%;
      justify-content: center;

      i-feather {
        color: var(--table-header-textColor);
      }

      i-feather[name='chevron-up'],
      i-feather[name='chevron-down'] {
        width: 16px;
        height: 10px;

        &.active>svg {
          color: var(--table-header-textColor);
          stroke-width: 4px;
        }
      }

      .feather-chevron-up,
      .feather-chevron-down {
        line-height: 0.7;
        cursor: pointer;
        stroke-width: 2.5px;
      }
    }
  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    color: var(--table-textColor) !important;
    padding: 16px !important;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    border-right: 0;
  }

  .img-size {
    width: 40px;
    height: 40px;
    margin-bottom: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  h4 {
    color: var(--table-textColor);
  }

  /* width */

  th.mat-mdc-header-cell.cell-check,
  td.mat-mdc-cell.cell-check {
    min-width: 0;
    width: 52px;
  }

  th.mat-mdc-header-cell.cell-xxs,
  td.mat-mdc-cell.cell-xxs {
    min-width: 50px;
  }

  th.mat-mdc-header-cell.action,
  td.mat-mdc-cell.action {
    width: 145px;
  }

  .cell-sm {
    width: 125px !important;
  }

  .cell-xs {
    min-width: 80px !important;
  }

  th.action-100,
  td.action-100 {
    width: 100px;
    min-width: 0;
  }
}

/* table wrapper */
.table-wrapper {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table {
      width: 100%;
      font-family: var(--table-font-family);
      border: 1px solid var(--separatorColor);
      background-color: var(--table-background);
    }

    mat-paginator {
      border-top: 1px solid transparent;
      padding-top: 10px;
    }
  }
}

/* ---- nested table ---- */

tr.mat-mdc-row.example-detail-row {
  height: 0;
}

.example-element-detail {
  overflow: hidden;
}

table td.mat-mdc-cell.cdk-column-expandedDetail {
  padding: 0 !important;
}

.nested-th-width {
  width: 33%;
}

.customGroups {

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    width: 50%;
  }

  .cdk-column-Name.mat-column-Name .head-in {
    font-family: var(--bold);
  }

  .description {
    font-size: 12px;
  }
}

th.mat-mdc-header-cell.center {
  text-align: center;
}

tr.mat-mdc-row.cdk-drag {
  display: table-row;
  background-color: var(--fgColor);
  font-family: var(--table-font-family) !important;
  cursor: move;

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    @include table-cell();

    padding-block: 12px;
  }
}

.inner-table {
  thead {
    display: none;
  }

  table tr td.mat-mdc-cell:first-child {
    padding-left: 30px !important;
  }
}

.table-AI {

  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    font-size: 14px;
    width: 128px;
    max-width: 128px;
    padding-right: 14px;
  }

  .table-responsive th.mat-mdc-header-cell:first-of-type,
  .table-responsive td.mat-mdc-cell:first-of-type,
  .table-responsive td.mat-mdc-footer-cell:first-of-type {
    min-width: 294px;
    max-width: 321px;
  }

  .example-detail-row {
    display: none;
  }

  .example-expanded-row+.example-detail-row {
    display: table-row;
  }

  .mat-column-expandedDetail {
    padding: 0 !important;

    .mat-mdc-table {
      border: none;
    }

    .mat-mdc-table thead {
      display: none;
    }
  }
}

.action-icons {
  display: flex;
  max-width: 80px;
  gap: 12px;
}

.search-filter {
  margin-left: 12px;
}