@use 'sass:map';

/* {##################   flex   ###################} */

@mixin flex($direction: null, $justify: null, $center: null) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $center;
}

@mixin flex-column($gap: null, $justify: null, $center: null) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  justify-content: $justify;
  align-items: $center;
}

@mixin flex-center($direction: row, $align: center, $justify: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin align-center($gap: null) {
  display: flex;
  align-items: center;
  gap: $gap;
}

/* {##################   Font   ###################} */

@mixin body-text($font-family: font-regular) {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-family: $font-family;
}

@mixin sm-text($font-family: font-regular) {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-family: $font-family;
  letter-spacing: 0.004em;
}

/* {##################   Breakpoints   ###################} */

$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

/* Mixin for generating media queries */
@mixin media($type, $size1, $size2: null) {
  $breakpoint-size1: map.get($breakpoints, $size1);

  @if not map-has-key($breakpoints, $size1) {
    @error "Breakpoint name '#{$size1}' does not exist in the breakpoints map.";
  }

  @if $type =='up' {
    @media (min-width: $breakpoint-size1) {
      @content;
    }
  }

  @else if $type =='down' {
    @media (max-width: $breakpoint-size1) {
      @content;
    }
  }

  @else if $type =='between' {
    @if $size2 ==null {
      @error "Type 'between' requires two breakpoint sizes.";
    }

    @if not map-has-key($breakpoints, $size2) {
      @error "Breakpoint name '#{$size2}' does not exist in the breakpoints map.";
    }

    $breakpoint-size2: map.get($breakpoints, $size2);

    @media (min-width: $breakpoint-size1) and (max-width: ($breakpoint-size2 - 0.02px)) {
      @content;
    }
  }
}

/* {##################   size   ###################} */

@mixin size($size) {
  width: $size;
  min-width: $size;
  height: $size;
}

/* {##################   Scrollbar   ###################} */

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(195, 193, 193, 0.5);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(195, 193, 193, 1);
  }
}

/* {##################   Triangle   ###################} */

@mixin triangle($size, $color: #fff, $direction: null) {
  position: absolute;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  top: -$size;

  @if $direction ==bottom {
    border-bottom: 0;
    border-top: $size solid $color;
  }

  @else {
    border-top: 0;
    border-bottom: $size solid $color;
  }
}

/* {##################   ellipsis/truncate   ###################} */

@mixin line-clamp($line: 2, $overflow: hidden) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: $overflow;
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* {##################   disabled checkbox   ###################} */

@mixin checkboxDisabled() {
  border-color: var(--toggle-deactivated-border);
  background:
    url('../../images/checked.svg') no-repeat center,
    var(--toggle-deactivated-background);
}

/* {##################   disabled button   ###################} */

@mixin buttonDisabled($opacity: null) {
  background-color: var(--buttonDisable);
  color: var(--disabledTextColor);
  border-color: var(--buttonDisable);
  opacity: $opacity;
}

/* {##################   Radio and Checkbox   ###################} */

@mixin radioCheck($radius) {
  appearance: none;
  height: var(--radioCheckSize);
  width: var(--radioCheckSize);
  background: var(--toggle-deactivated-background);
  border-radius: $radius;
}

/* {##################   Text Box Related  ###################} */

@mixin txtbox {
  background-color: var(--txtbox-enabled);
  color: var(--primaryTextColor);
  font-size: var(--txtbox-font-size);
  line-height: var(--txtbox-line-height);
  font-family: var(--txtbox-font-family);
  padding: 8px;
}

@mixin input-hover {
  background-color: var(--txtbox-enabled-hover);
  color: var(--primaryTextColor);
}

@mixin input-focus {
  background-color: var(--txtbox-focus-background);
  border: 1px solid var(--txtbox-focus-border);
  outline: 4px solid var(--txtbox-focus-outline);
}

@mixin check-radio {
  @include size(20px);

  margin-top: 0;
  cursor: pointer;
  background: var(--toggle-deactivated-background);
  border-color: var(--toggle-deactivated-border) !important;
  border-bottom-width: 2px !important;
  box-shadow: none;
  transition: none;

  &:checked {
    outline: none;
    border-color: var(--toggle-activated-border) !important;
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}

/* {##################      ###################} */