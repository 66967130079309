@use 'src/assets/styles/base/mixins' as *;

.system-tabs {
  .mat-horizontal-content-container {
    padding: 0;
    overflow: initial;
  }

  .mat-step-header .mat-step-icon,
  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-horizontal-stepper-header-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 44px;
  }

  .mat-step-header {
    padding: 0;
    height: 32px;

    .mat-step-label {
      font-size: 16px;
      font-family: var(--medium);
      color: var(--secondaryTextColor);
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      transition: 0.5s all;

      &.mat-step-label-selected {
        font-family: var(--bold);
        border-bottom-color: var(--primaryColor);
      }

      .mat-step-text-label {
        line-height: 1.1;
      }
    }
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background-color: transparent;
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: transparent;
  }

  .mat-step-header .mat-step-header-ripple {
    display: none;
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: unset;
    font-family: var(--regular);
  }
}