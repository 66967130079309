@use 'src/assets/styles/base/mixins' as *;

.resourceForm {
  .left-col {
    width: 50%;
  }

  .right-copy {
    width: calc(50% - 24px);

    h2 {
      font-size: 20px;
    }

    .copy-resource-wrap {
      align-items: center;
    }

    .btn-group {
      margin-top: 5px;

      .btn {
        padding: 0.54rem 0.5rem;
      }
    }
  }
}

.resourse-wrapper {
  .two-col-right {
    display: flex;

    .col_res {
      position: relative;
      padding: 12px 0;

      span {
        position: absolute;
        top: 12px;
      }
    }

    .left-col {
      width: 60%;
      margin-right: 24px;
    }

    .mat-mdc-paginator-container {
      padding: 0;
    }

    .right-col {
      width: calc(40% - 24px);

      .select-position {
        position: relative;

        .res-dialog-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 206px;
          opacity: 0;
        }
      }
    }
  }
}

.resources-check {
  display: flex;

  .form-check {
    display: flex;
    align-items: center;
    width: 33%;
  }

  label {
    margin-left: 8px;
    margin-bottom: 0;
  }
}

.resources-tag {
  input {
    padding-right: 32px;
  }

  i-feather[name='search'] {
    position: absolute;
    left: 180px;
    top: 34px;
  }
}

.mat-mdc-chip.clip-Prog.mat-mdc-standard-chip:not(.mat-mdc-chip-disabled):active {
  box-shadow: none;
}

.clip-Prog.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  font-size: 12px;
  line-height: 12px;
  padding: 8px;
  color: var(--primaryTextColor);
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 0.6rem;

  .mat-mdc-chip-ripple {
    display: none;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  opacity: 0.4;
  border: 0;
  background: none;
  padding: 0 0 0 10px;
}

@include media('down', md) {
  .resourse-wrapper {
    @include media('down', sm) {
      table.mat-mdc-table {
        width: 855px;
        overflow: auto;
      }

      // .d-flex.title-bar {
      //   display: block !important;
      // }

      .mat-mdc-paginator-container {
        display: block;
      }

      .mat-mdc-paginator-range-actions {
        display: block;
        text-align: center;
      }

      .mat-mdc-paginator-range-label {
        margin: 0;
      }

      .mat-mdc-paginator-page-size {
        margin-right: 0;
        justify-content: center;
      }
    }

    .two-col-right {
      display: block;

      .left-col {
        width: 100%;
        margin-right: 0;
      }

      .right-col {
        width: 100%;
        margin-top: 30px;

        @include media('down', sm) {
          .button-file {
            .d-flex {
              display: block !important;

              .btn {
                display: inline-block;
                height: 36px;
                margin: 10px 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .resourceForm {
    .right-copy {
      width: 100%;
      margin-top: 20px;

      .copy-resource-wrap {
        margin-bottom: 20px;
        display: block !important;

        .form-group {
          width: 100%;
        }
      }
    }
  }
}

figure.img-size {
  width: 32px;
  height: 32px;
  padding: 2px;
  margin: 0;

  img {
    max-width: 100%;
  }
}

.cdk-drag-preview {
  .img-size img {
    position: relative;
    width: 28px;
    height: 28px;
  }
}

/* --- div structure resource ui styling --- */

.col-res {
  padding: 16px;
  border-right: 1px solid var(--separatorColor);

  &:last-child {
    border-right: none;
  }
}

.col-res>div {
  display: flex;
  align-items: center;

  &:has(.copy-resource-start) {
    justify-content: center;
  }

  &:has(.content.table-wrapper) {
    width: 100%;
  }
}

.associate-task-dialog {
  .col-res {
    padding: 8px 0;
  }

  .col-name-inner {
    gap: 0 !important;
  }
}

.col-name {
  width: 85%;
}

.col-image {
  width: 15%;
  overflow: hidden;
}

.col-recommended {
  width: 15%;
}

.col-actions {
  width: 15%;

  .col-action-icons {
    width: 90px;
    display: flex;
    justify-content: space-between;
    color: var(--secondaryTextColor);
  }
}

app-copy-resources {
  .col-name {
    width: 80%;
  }

  .col-image {
    width: 20%;
  }
}

.resource-row-head {
  display: flex;
  font-family: var(--medium);
  border-bottom: 1px solid var(--separatorColor);

  .col-res {
    @include flex(null, space-between, center);

    padding: 12px 16px;
    background: var(--primaryColor);
    color: var(--textColorOnPrimary);
  }

  span.res-sort {
    display: inline-flex;
    flex-direction: column;
    color: var(--disabledTextColor);

    i-feather.feather-20 {
      height: 10px;
    }

    i-feather.feather-20.active {
      color: var(--primaryTextColor);
    }
  }
}

.resource-row {
  display: flex;
  flex-direction: column;

  &.has-sublist {
    flex-direction: column;
  }

  .resource-row-inner {
    display: flex;
  }

  .col-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondaryTextColor);
    position: relative;

    .resource-title {
      padding-left: 10px;
    }
  }

  .col-image {
    display: flex;
    justify-content: center;
  }

  .col-recommended {
    text-align: center;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  height: auto;
  background: var(--fgColor);
  border-top: #d9d9d9 1px solid;
  border-bottom: #d9d9d9 1px solid;
  display: flex;
  flex-direction: column;
}

.content.table-wrapper {
  .col-name {
    width: 90%;
  }

  .col-name-inner {
    @include align-center(10px);

    &:has(.content.table-wrapper) {
      width: 100%;
    }

    .resource-title {
      padding-left: 0;
    }
  }

  &:has(.associate-goals-dialog) {
    .col-name-inner .resource-title {
      padding-left: 10px !important;
    }
  }

  .col-actions {
    width: 10%;
  }

  .col-action-icons {
    width: 100%;
  }
}

/* css for resources */
.content.table-wrapper {
  .col-res.col-actions>div {
    justify-content: center;
  }

  .col-action-icons {
    justify-content: center;
  }

  .action-icons .feather-20 {
    margin-right: 0;
    text-align: center;
  }
}

.cg-view {
  .content.table-wrapper {
    margin-top: -12px;

    .resource-list-group {
      border: none;

      .resource-row-head {
        display: none;
      }

      .col-res.col-name {
        width: 100% !important;
      }

      &::after {
        display: none;
      }
    }
  }
}

.gen-table-wrapper {
  overflow-x: auto;
  // border-left: 1px solid var(--separatorColor);
  border: 1px solid var(--separatorColor);
}

.gen-grid {
  display: grid;
  //   grid-template-columns: minmax(400px, 2fr) repeat(5, minmax(200px, 1fr));
  border-bottom: 1px solid var(--separatorColor);

}

.gen-subgrid {
  display: grid;
  grid-template-columns: subgrid;
  //   grid-column: span 6;
}

.gen-table-th-row {
  background: var(--primaryColor);
  color: var(--textColorOnPrimary);
  border-bottom: 1px solid var(--separatorColor);

  &>* {
    background: var(--primaryColor);
    border-right: 1px solid var(--separatorColor);
    padding: 12px 16px;
  }
}

.activity-detail-text {
  word-break: break-word;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-preview {
  border: none;
  box-shadow: var(--card-box-shadow);
}

.main-resource-list:not(:first-child) .level-broder {
  border-left: 1px dashed var(--separatorColor);
}

.main-resource-list .child-resource-list .gen-table-td-row {
  position: relative;

  &::before {
    top: 26px;
    position: absolute;
    content: '';
    height: 0;
    width: 16px;
    border-top: 1px dashed var(--separatorColor);
  }
}

.level-1-space-2x>.gen-subgrid>.child-resource-list {
  margin-left: 30px !important;
}