@use 'src/assets/styles/base/mixins' as *;

.cg-row {
  display: flex;
  gap: 24px;

  .common-card {
    width: calc(50% - 12px);
  }

  .card-titlebar {
    i-feather {
      color: var(--secondaryTextColor);
    }
  }
}

.cg-card-header {
  padding-bottom: 18px;
}

.cg-list-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--secondaryTextColor);

  .cg-list-item {
    display: flex;
    gap: 12px;
  }

  input[type='checkbox'] {
    display: none;

    & + label {
      margin-left: 0 !important;
    }
  }

  &.edit-card {
    input[type='checkbox'] {
      display: initial;

      & + label {
        margin-left: 12px !important;
      }
    }
  }
}

.cg-vitals-header {
  .vitals-header-icons {
    display: flex;
    gap: 20px;
    color: var(--secondaryTextColor);
  }
}

.vitals-table-varient {
  padding: 5px 0;

  .table-responsive {
    border: 1px solid var(--separatorColor);
  }
}

.vitals-tab-badge {
  position: absolute;
  background: var(--defaultGraySecondary);
  border-radius: 50%;
  padding: 2px 4px;
  text-align: center;
  min-width: 23px;
  display: inline-block;
  border: 2px solid #fff;
  right: -10px;
  top: -10px;
  color: var(--secondaryTextColor);
}

.bp-type-buttons {
  padding: 20px;

  button {
    position: relative;
    border-bottom: none;
  }

  .btn-secondary {
    border-radius: 0;
  }
}
