@use 'src/assets/styles/base/mixins' as *;

.card-titlebar {
  @include flex(row, flex-end, center);

  & > .btn.me-2 {
    margin-right: 0 !important;
  }

  .btn + .btn {
    margin-left: 16px;
  }

  padding-bottom: 24px;
}

.card-header-icons {
  @include flex(null, flex-end);

  gap: 16px;
  color: var(--secondaryTextColor);

  i-feather {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  .card {
    border: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  .card-body {
    letter-spacing: 0.004em;
    padding: 8px;

    .card-text {
      @include line-clamp();
    }
  }

  .card-label {
    font-family: var(--bold);
    min-width: 130px;
  }

  .user-count {
    padding-right: 24px;
    line-height: 1;

    @include flex(null, null, flex-start);

    h3 {
      margin: 0 0 0 4px;
      font-family: var(--medium);
      letter-spacing: 0.005em;
    }
  }

  .card-footer {
    padding: 8px;
    border-width: 0;
    background: transparent;
  }
}

.card-base {
  background-color: var(--fgColor);
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 24px;
  box-shadow: var(--card-box-shadow);
}

.common-card {
  @extend .card-base;

  .common-card-header {
    padding-bottom: 24px;
  }

  &.p20 {
    padding: 20px;

    .card-titlebar {
      padding-bottom: 16px;
    }
  }
}

.card-buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
}

@include media('down', lg) {
  .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);

    .card-label {
      min-width: 80px;
    }
  }
}

@include media('down', md) {
  .cards-wrapper {
    grid-template-columns: 1fr;

    .card {
      border: 0;
    }
  }

  .card-base {
    padding: 20px;
  }
}
