@use 'src/assets/styles/base/mixins' as *;

.client-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.client-breadcrumb {
  margin: 0;
  padding: 0;

  li {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
    font-family: var(--medium);
  }
}

.client-menu-img {
  @include flex(null, center, center);

  width: 40px;
  height: 40px;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-inline: 0;
  color: var(--primaryTextColor);
  max-width: 75dvw;

  @include ellipsis();

  &::before {
    content: url('../../images/icons/breadcrumb-agle-right.svg');
    padding-inline: 0;
    transform: scale(0.8);
  }
}

.breadcrumb {
  color: var(--primaryTextColor);
  margin-bottom: 0;

  a,
  a:not([href]) {
    color: var(--linkTextColor);

    &:hover {
      color: var(--linkTextColor);
    }
  }

  .active-only {
    padding-bottom: 3px;
  }
}
