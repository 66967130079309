@use 'src/assets/styles/base/mixins' as *;

/* #######   Flex   ####### */

.flex-center {
  @include flex(null, center, center);
}

.flex-between {
  @include flex(null, space-between, center);
}

.flex-end {
  @include flex-center(null, center, flex-end);
}

/* --- */

strong {
  font-family: var(--bold);
  font-weight: 400;

  &.medium {
    font-family: var(--medium);
  }
}

.icon-text-10 {
  display: flex;
  align-items: center;
  gap: 10px;

  &.start {
    align-items: flex-start;
  }
}

.break-word {
  word-break: break-word;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.module-seperator {
  border-bottom: 1px solid var(--defaultGraySecondary);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

/* #######   small = 12px classes   ####### */

.info-small {
  font-size: var(--small-font-size);
  color: var(--secondaryTextColor);
  padding-top: 8px;
}

.sm-text {
  @include sm-text();
}

small {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
}

/* #######   disabled   ####### */

.disabled {
  color: var(--disabledTextColor);
  pointer-events: none;

  & * {
    color: var(--disabledTextColor) !important;
  }

  img {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.disabled {
  .action-icons {
    i-feather {
      pointer-events: initial;

      svg {
        stroke: var(--secondaryTextColor);
      }
    }
  }
}

input:disabled {
  color: var(--disabledTextColor);

  & + label {
    color: var(--disabledTextColor);
  }
}

/* #######   Font Family   ####### */

$fontFamily: (
  regular: var(--regular),
  medium: var(--medium),
  bold: var(--bold),
);

@each $key, $value in $fontFamily {
  .ff-#{$key} {
    font-family: $value;
  }
}

/* Generated font-family classes */
/* .ff-regular, .ff-medium, .ff-bold */

/* #######   Text color    ####### */

$textColors: (
  primary: var(--primaryTextColor),
  secondary: var(--secondaryTextColor),
  success: var(--positiveColor),
  disabled: var(--disabledTextColor),
  link: var(--linkTextColor),
  error: var(--negativeColor),
);

@each $key, $value in $textColors {
  .text-#{$key} {
    color: $value !important;
  }
}

/* Generated text color classes */
/* .text-primary, .text-secondary, .text-success, .text-disabled, .text-link, .text-error */

/* #######   Cursor   ####### */

.pointer {
  cursor: pointer;
}

$cursorValues: default, auto, grab, move, pointer;

@each $cursor in $cursorValues {
  .cs-#{$cursor} {
    cursor: $cursor;
  }
}

/* Generated cursor classes */
/* .cs-default, .cs-auto, .cs-grab, .cs-move, .cs-pointer */

$white-space: normal, nowrap, pre, pre-line, pre-wrap;

@each $ws in $white-space {
  .ws-#{$ws} {
    white-space: $ws;
  }
}

/* Generated white-space classes */
/* .ws-normal, .ws-nowrap, .ws-pre, .ws-pre-line, .ws-pre-wrap */

$zIndex: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $value in $zIndex {
  .index-#{$value} {
    z-index: $value;
  }
}

/* Generated z-index classes */
/* .index-0, .index-1, .index-2, .index-3, .z-index-4, .z-index-6, .z-index-7, .z-index-8, .z-index-9, .z-index-10 */
